import React from "react";
import { createContext } from "react";
import { useState } from "react";
import { LOCATION_TYPE } from "../constants/locationType";


export const LocationTypeContext = createContext({
  type: LOCATION_TYPE.Unknown,
  setLocationType: () => {},
});

export const LocationTypeProvider = ({ children }) => {
  const [type, setType] = useState(LOCATION_TYPE.Unknown);

  return (
    <LocationTypeContext.Provider
      value={{ type, setLocationType: setType }}
    >
      {children}
    </LocationTypeContext.Provider>
  );
};
