import { useTranslation } from "react-i18next";

export default function Main() {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t("Main.Title")}</h3>
      <a href="https://seatlio.com">{t("Main.Link")}</a>
    </>
  );
}
