import back from "../images/back.png";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { ReservationDataContext } from "../context/reservationDataContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { useMatch, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguagePicker from "../components/LanguagePicker";

export default function Navigation() {
  const [displayBackButton, setDisplayBackButton] = useState(false);
  const { steps = [] } = useContext(ReservationDataContext);
  const { step, prevStep, filteredSteps, title } = useContext(
    ReservationNavigationContext
  );
  const { reservation } = useContext(ReservationEntryContext);

  const match = useMatch("/:companySlug/*");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackButton = () => {
    if (filteredSteps[0]?.id === step?.id) {
      navigate(match?.params?.companySlug);
      return;
    }
    prevStep(reservation.entries[0].units);
  };

  useEffect(() => {
    if (steps[steps.length - 1]?.id !== step?.id) {
      setDisplayBackButton(true);
      return;
    }
    setDisplayBackButton(false);
  }, [step, steps]);

  return (
    <nav className="nav">
      <div className="navigation-bar container">
        <div className="item-left">
          {displayBackButton ? (
            <img alt="back button" src={back} onClick={handleBackButton} />
          ) : (
            <ul className="menu-list">
              <li>
                <a style={{color: '#edf2f7'}} href="https://seatlio.com/terms-of-agreement.html">
                  {t("Legal")}
                </a>
              </li>
            </ul>
          )}
        </div>
        <div className="item-center">
          <ul className="menu-list">
            <li>
              <b>{title}</b>
            </li>
          </ul>
        </div>

        <div className="item-right">
          <div className="item-right">
            <div className="dropdown">
              <LanguagePicker />
            </div>
          </div>
        </div>
      </div>
      <div className="menu-backdrop"></div>
    </nav>
  );
}
