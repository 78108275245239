export const COUNTRY_CODES = 
[
	{
		lang: 'en',
		data: [
			{
				label: 'Afghanistan',
				value: '+93',
				countryId: '1B09274B-01B1-42D3-D23C-08DB4187D218',
			},
			{
				label: 'Åland Islands',
				value: '+358',
				countryId: 'CC83162E-61A2-4C2C-D23D-08DB4187D218',
			},
			{
				label: 'Albania',
				value: '+355',
				countryId: 'EF40F256-C64F-4DBC-D23E-08DB4187D218',
			},
			{
				label: 'Algeria',
				value: '+213',
				countryId: 'DF845A91-198C-4858-D23F-08DB4187D218',
			},
			{
				label: 'American Samoa',
				value: '+1',
				countryId: 'E1639038-BDE3-4993-D240-08DB4187D218',
			},
			{
				label: 'Andorra',
				value: '+376',
				countryId: '4A8763CD-0165-43F6-D241-08DB4187D218',
			},
			{
				label: 'Angola',
				value: '+244',
				countryId: '2E620669-4E42-468F-D242-08DB4187D218',
			},
			{
				label: 'Anguilla',
				value: '+1',
				countryId: '3AC88E68-F7A1-4C75-D243-08DB4187D218',
			},
			{
				label: 'Antarctica',
				value: '+672',
				countryId: 'A3185321-A629-4BC6-D244-08DB4187D218',
			},
			{
				label: 'Antigua and Barbuda',
				value: '+1',
				countryId: '28D61ED5-CDD7-4AC4-D245-08DB4187D218',
			},
			{
				label: 'Argentina',
				value: '+54',
				countryId: '1FAD35E2-EE0F-449B-D246-08DB4187D218',
			},
			{
				label: 'Armenia',
				value: '+374',
				countryId: '9F978A84-682B-4864-D247-08DB4187D218',
			},
			{
				label: 'Aruba',
				value: '+297',
				countryId: 'EF35A40E-DBEB-45CA-D248-08DB4187D218',
			},
			{
				label: 'Australia',
				value: '+61',
				countryId: '6CA6364C-34CE-4B40-D249-08DB4187D218',
			},
			{
				label: 'Austria',
				value: '+43',
				countryId: 'CBB38F8C-5EA8-4ABF-D24A-08DB4187D218',
			},
			{
				label: 'Azerbaijan',
				value: '+994',
				countryId: '0D74391A-64E0-48F3-D24B-08DB4187D218',
			},
			{
				label: 'Bahamas',
				value: '+1',
				countryId: '45FE54CB-9D7B-47A2-D24C-08DB4187D218',
			},
			{
				label: 'Bahrain',
				value: '+973',
				countryId: '19007079-1F3D-47C5-D24D-08DB4187D218',
			},
			{
				label: 'Bangladesh',
				value: '+880',
				countryId: '8E56DC92-B1C9-4592-D24E-08DB4187D218',
			},
			{
				label: 'Barbados',
				value: '+1',
				countryId: 'B675C7E6-CA19-4F77-D24F-08DB4187D218',
			},
			{
				label: 'Belarus',
				value: '+375',
				countryId: 'B6862AC3-FA76-4352-D250-08DB4187D218',
			},
			{
				label: 'Belgium',
				value: '+32',
				countryId: 'F6B6E355-5739-4A61-D251-08DB4187D218',
			},
			{
				label: 'Belize',
				value: '+501',
				countryId: 'FD5FB6ED-8C78-4103-D252-08DB4187D218',
			},
			{
				label: 'Benin',
				value: '+229',
				countryId: '6C6C81EE-9DC1-4C93-D253-08DB4187D218',
			},
			{
				label: 'Bermuda',
				value: '+1',
				countryId: '82E4C244-69DA-45A4-D254-08DB4187D218',
			},
			{
				label: 'Bhutan',
				value: '+975',
				countryId: 'D4929593-E0CD-427A-D255-08DB4187D218',
			},
			{
				label: 'Bolivia (Plurinational State of)',
				value: '+591',
				countryId: '46625A1B-0564-41A9-D256-08DB4187D218',
			},
			{
				label: 'Bonaire, Sint Eustatius and Saba',
				value: '+599',
				countryId: '2C4D70D4-4C23-4066-D257-08DB4187D218',
			},
			{
				label: 'Bosnia and Herzegovina',
				value: '+387',
				countryId: '5BEABFDC-745C-41D9-D258-08DB4187D218',
			},
			{
				label: 'Botswana',
				value: '+267',
				countryId: 'FEF34A6F-D555-456D-D259-08DB4187D218',
			},
			{
				label: 'Bouvet Island',
				value: '+47',
				countryId: 'A8CC326B-DCEF-43AB-D25A-08DB4187D218',
			},
			{
				label: 'Brazil',
				value: '+55',
				countryId: '809F4023-6033-426C-D25B-08DB4187D218',
			},
			{
				label: 'British Indian Ocean Territory',
				value: '+246',
				countryId: '8EA884E8-5694-4C44-D25C-08DB4187D218',
			},
			{
				label: 'United States Minor Outlying Islands',
				value: '+246',
				countryId: 'E416F286-4EBE-4ACF-D25D-08DB4187D218',
			},
			{
				label: 'Virgin Islands (British)',
				value: '+1',
				countryId: 'FD484869-FE2B-4C0F-D25E-08DB4187D218',
			},
			{
				label: 'Virgin Islands (U.S.)',
				value: '+1 340',
				countryId: '5DFB7435-068C-4E57-D25F-08DB4187D218',
			},
			{
				label: 'Brunei Darussalam',
				value: '+673',
				countryId: '34317268-4377-44E1-D260-08DB4187D218',
			},
			{
				label: 'Bulgaria',
				value: '+359',
				countryId: '309DC380-B6D6-4F63-D261-08DB4187D218',
			},
			{
				label: 'Burkina Faso',
				value: '+226',
				countryId: 'DACEB0BC-0ACD-4EF8-D262-08DB4187D218',
			},
			{
				label: 'Burundi',
				value: '+257',
				countryId: '63F42FF5-27E9-41B8-D263-08DB4187D218',
			},
			{
				label: 'Cambodia',
				value: '+855',
				countryId: '8BB33699-1E92-418D-D264-08DB4187D218',
			},
			{
				label: 'Cameroon',
				value: '+237',
				countryId: '17AFF4AA-8DA4-403F-D265-08DB4187D218',
			},
			{
				label: 'Canada',
				value: '+1',
				countryId: '25F4EC32-AD21-4E0F-D266-08DB4187D218',
			},
			{
				label: 'Cabo Verde',
				value: '+238',
				countryId: '15203231-F793-47B7-D267-08DB4187D218',
			},
			{
				label: 'Cayman Islands',
				value: '+1',
				countryId: '7E44AD12-351B-4499-D268-08DB4187D218',
			},
			{
				label: 'Central African Republic',
				value: '+236',
				countryId: 'D6AD317E-1768-4755-D269-08DB4187D218',
			},
			{
				label: 'Chad',
				value: '+235',
				countryId: 'D12A58C7-7838-4B4F-D26A-08DB4187D218',
			},
			{
				label: 'Chile',
				value: '+56',
				countryId: 'B3B11221-2530-4FB9-D26B-08DB4187D218',
			},
			{
				label: 'China',
				value: '+86',
				countryId: '9B69BDDB-9B9B-40FB-D26C-08DB4187D218',
			},
			{
				label: 'Christmas Island',
				value: '+61',
				countryId: '1525E435-647C-43E0-D26D-08DB4187D218',
			},
			{
				label: 'Cocos (Keeling) Islands',
				value: '+61',
				countryId: '62896E7E-CA0D-438B-D26E-08DB4187D218',
			},
			{
				label: 'Colombia',
				value: '+57',
				countryId: '75280D5A-0CF2-46EE-D26F-08DB4187D218',
			},
			{
				label: 'Comoros',
				value: '+269',
				countryId: '668BA360-7AB7-4E36-D270-08DB4187D218',
			},
			{
				label: 'Congo',
				value: '+242',
				countryId: '2279885C-E4DD-4DFF-D271-08DB4187D218',
			},
			{
				label: 'Congo (Democratic Republic of the)',
				value: '+243',
				countryId: '8773BCE1-2D1E-4324-D272-08DB4187D218',
			},
			{
				label: 'Cook Islands',
				value: '+682',
				countryId: 'A4E85595-C6A1-4DB7-D273-08DB4187D218',
			},
			{
				label: 'Costa Rica',
				value: '+506',
				countryId: '4751E799-9F8A-49C1-D274-08DB4187D218',
			},
			{
				label: 'Croatia',
				value: '+385',
				countryId: '361DF93A-C799-4B42-D275-08DB4187D218',
			},
			{
				label: 'Cuba',
				value: '+53',
				countryId: '0915AA13-5CF4-48C8-D276-08DB4187D218',
			},
			{
				label: 'Curaçao',
				value: '+599',
				countryId: '4B51797D-7977-4CA4-D277-08DB4187D218',
			},
			{
				label: 'Cyprus',
				value: '+357',
				countryId: 'C8A504B7-37F9-4EE9-D278-08DB4187D218',
			},
			{
				label: 'Czech Republic',
				value: '+420',
				countryId: '6B4067BD-BA67-4037-D279-08DB4187D218',
			},
			{
				label: 'Denmark',
				value: '+45',
				countryId: '2F4BFB26-36A5-4ABD-D27A-08DB4187D218',
			},
			{
				label: 'Djibouti',
				value: '+253',
				countryId: 'ABC20812-3367-40AF-D27B-08DB4187D218',
			},
			{
				label: 'Dominica',
				value: '+1',
				countryId: '08AA6F10-050F-47A8-D27C-08DB4187D218',
			},
			{
				label: 'Dominican Republic',
				value: '+1',
				countryId: 'DAA52F5F-8B24-4D4B-D27D-08DB4187D218',
			},
			{
				label: 'Ecuador',
				value: '+593',
				countryId: '8CB0A48C-C88E-4541-D27E-08DB4187D218',
			},
			{
				label: 'Egypt',
				value: '+20',
				countryId: '5BB3D720-C2D3-4288-D27F-08DB4187D218',
			},
			{
				label: 'El Salvador',
				value: '+503',
				countryId: '3C979B68-9D7B-4FF6-D280-08DB4187D218',
			},
			{
				label: 'Equatorial Guinea',
				value: '+240',
				countryId: 'D07F42AA-3489-4AA3-D281-08DB4187D218',
			},
			{
				label: 'Eritrea',
				value: '+291',
				countryId: '18D391F9-75DA-42FE-D282-08DB4187D218',
			},
			{
				label: 'Estonia',
				value: '+372',
				countryId: '9E97A3BB-EE5D-4BC1-D283-08DB4187D218',
			},
			{
				label: 'Ethiopia',
				value: '+251',
				countryId: '7B97981D-B489-48D6-D284-08DB4187D218',
			},
			{
				label: 'Falkland Islands (Malvinas)',
				value: '+500',
				countryId: '4FDEC645-8059-442D-D285-08DB4187D218',
			},
			{
				label: 'Faroe Islands',
				value: '+298',
				countryId: '1CD78B10-2A61-46A1-D286-08DB4187D218',
			},
			{
				label: 'Fiji',
				value: '+679',
				countryId: '725189E7-0326-4A14-D287-08DB4187D218',
			},
			{
				label: 'Finland',
				value: '+358',
				countryId: 'DA0C0248-69DA-4D6E-D288-08DB4187D218',
			},
			{
				label: 'France',
				value: '+33',
				countryId: '23DB6CF5-B064-4F1E-D289-08DB4187D218',
			},
			{
				label: 'French Guiana',
				value: '+594',
				countryId: '1AE42273-48D4-46CF-D28A-08DB4187D218',
			},
			{
				label: 'French Polynesia',
				value: '+689',
				countryId: 'F5259A6C-7A92-4BA9-D28B-08DB4187D218',
			},
			{
				label: 'French Southern Territories',
				value: '+262',
				countryId: 'B60527BA-FFE5-4D79-D28C-08DB4187D218',
			},
			{
				label: 'Gabon',
				value: '+241',
				countryId: 'EE8559E2-83D4-49D9-D28D-08DB4187D218',
			},
			{
				label: 'Gambia',
				value: '+220',
				countryId: 'E3121FF7-82B2-497E-D28E-08DB4187D218',
			},
			{
				label: 'Georgia',
				value: '+995',
				countryId: 'E82D2BE9-7CC9-4CBB-D28F-08DB4187D218',
			},
			{
				label: 'Germany',
				value: '+49',
				countryId: '8D05C85E-189A-4FCA-D290-08DB4187D218',
			},
			{
				label: 'Ghana',
				value: '+233',
				countryId: '8E439BA0-EA86-4037-D291-08DB4187D218',
			},
			{
				label: 'Gibraltar',
				value: '+350',
				countryId: '76812F36-B39F-477A-D292-08DB4187D218',
			},
			{
				label: 'Greece',
				value: '+30',
				countryId: '427102DA-FDF2-4B60-D293-08DB4187D218',
			},
			{
				label: 'Greenland',
				value: '+299',
				countryId: '2657765E-9060-414F-D294-08DB4187D218',
			},
			{
				label: 'Grenada',
				value: '+1',
				countryId: '2544DD30-EE13-424D-D295-08DB4187D218',
			},
			{
				label: 'Guadeloupe',
				value: '+590',
				countryId: '38B06F3F-9DCE-4807-D296-08DB4187D218',
			},
			{
				label: 'Guam',
				value: '+1',
				countryId: 'E98775F2-CC2E-4240-D297-08DB4187D218',
			},
			{
				label: 'Guatemala',
				value: '+502',
				countryId: '752945A5-39F0-4985-D298-08DB4187D218',
			},
			{
				label: 'Guernsey',
				value: '+44',
				countryId: '85CC5DE3-81D4-44E8-D299-08DB4187D218',
			},
			{
				label: 'Guinea',
				value: '+224',
				countryId: '834F5902-6A03-4928-D29A-08DB4187D218',
			},
			{
				label: 'Guinea-Bissau',
				value: '+245',
				countryId: '5B5B6788-6300-4656-D29B-08DB4187D218',
			},
			{
				label: 'Guyana',
				value: '+592',
				countryId: '700346CE-EC9D-43C7-D29C-08DB4187D218',
			},
			{
				label: 'Haiti',
				value: '+509',
				countryId: 'F21838D8-FA10-486D-D29D-08DB4187D218',
			},
			{
				label: 'Heard Island and McDonald Islands',
				value: '+672',
				countryId: '6D9D8F41-BCE8-4A63-D29E-08DB4187D218',
			},
			{
				label: 'Vatican City',
				value: '+379',
				countryId: '206260A5-B5D1-4CAF-D29F-08DB4187D218',
			},
			{
				label: 'Honduras',
				value: '+504',
				countryId: '4789FF76-9F84-477E-D2A0-08DB4187D218',
			},
			{
				label: 'Hungary',
				value: '+36',
				countryId: '3FBB6A91-AD4F-47B2-D2A1-08DB4187D218',
			},
			{
				label: 'Hong Kong',
				value: '+852',
				countryId: 'EAA8EFBC-3D64-449D-D2A2-08DB4187D218',
			},
			{
				label: 'Iceland',
				value: '+354',
				countryId: '61FE256A-FF4F-493E-D2A3-08DB4187D218',
			},
			{
				label: 'India',
				value: '+91',
				countryId: '79A4ACA8-ECBE-428B-D2A4-08DB4187D218',
			},
			{
				label: 'Indonesia',
				value: '+62',
				countryId: '67750109-EABF-4D4D-D2A5-08DB4187D218',
			},
			{
				label: 'Ivory Coast',
				value: '+225',
				countryId: '176C9359-C50D-4443-D2A6-08DB4187D218',
			},
			{
				label: 'Iran (Islamic Republic of)',
				value: '+98',
				countryId: '928E7D24-DCF3-4534-D2A7-08DB4187D218',
			},
			{
				label: 'Iraq',
				value: '+964',
				countryId: '71D166CF-5BD4-404D-D2A8-08DB4187D218',
			},
			{
				label: 'Ireland',
				value: '+353',
				countryId: '93EFB762-34F0-43AD-D2A9-08DB4187D218',
			},
			{
				label: 'Isle of Man',
				value: '+44',
				countryId: '54758AB6-2597-41BB-D2AA-08DB4187D218',
			},
			{
				label: 'Israel',
				value: '+972',
				countryId: '57109F72-C10B-484F-D2AB-08DB4187D218',
			},
			{
				label: 'Italy',
				value: '+39',
				countryId: '4ED0F94A-97E5-4757-D2AC-08DB4187D218',
			},
			{
				label: 'Jamaica',
				value: '+1',
				countryId: '46BE78C4-3F21-4273-D2AD-08DB4187D218',
			},
			{
				label: 'Japan',
				value: '+81',
				countryId: 'B363379A-CABE-4667-D2AE-08DB4187D218',
			},
			{
				label: 'Jersey',
				value: '+44',
				countryId: '2414F354-7623-4C46-D2AF-08DB4187D218',
			},
			{
				label: 'Jordan',
				value: '+962',
				countryId: '044B8B40-5D7B-4E23-D2B0-08DB4187D218',
			},
			{
				label: 'Kazakhstan',
				value: '+76',
				countryId: '5EE7E7D0-A9CA-4477-D2B1-08DB4187D218',
			},
			{
				label: 'Kenya',
				value: '+254',
				countryId: '6EFCB7CF-68C2-4D2E-D2B2-08DB4187D218',
			},
			{
				label: 'Kiribati',
				value: '+686',
				countryId: '19B77088-EDD7-403A-D2B3-08DB4187D218',
			},
			{
				label: 'Kuwait',
				value: '+965',
				countryId: '2D2D11AD-D154-4379-D2B4-08DB4187D218',
			},
			{
				label: 'Kyrgyzstan',
				value: '+996',
				countryId: '0B574E0A-7E18-4EE5-D2B5-08DB4187D218',
			},
			{
				label: 'Lao Peoples Democratic Republic',
				value: '+856',
				countryId: '83CA3A51-2B16-47AE-D2B6-08DB4187D218',
			},
			{
				label: 'Latvia',
				value: '+371',
				countryId: 'F4007A8A-3037-497C-D2B7-08DB4187D218',
			},
			{
				label: 'Lebanon',
				value: '+961',
				countryId: '15EDB12D-FACB-4360-D2B8-08DB4187D218',
			},
			{
				label: 'Lesotho',
				value: '+266',
				countryId: 'CB8D3527-C786-4C18-D2B9-08DB4187D218',
			},
			{
				label: 'Liberia',
				value: '+231',
				countryId: 'C78F54CD-B8C2-401D-D2BA-08DB4187D218',
			},
			{
				label: 'Libya',
				value: '+218',
				countryId: '9F25A005-5922-4DEB-D2BB-08DB4187D218',
			},
			{
				label: 'Liechtenstein',
				value: '+423',
				countryId: '9A7A3629-A123-45CF-D2BC-08DB4187D218',
			},
			{
				label: 'Lithuania',
				value: '+370',
				countryId: 'CC831D13-CA5A-4FEA-D2BD-08DB4187D218',
			},
			{
				label: 'Luxembourg',
				value: '+352',
				countryId: '9EE9CD41-26CF-45AF-D2BE-08DB4187D218',
			},
			{
				label: 'Macao',
				value: '+853',
				countryId: '7530B578-1950-4672-D2BF-08DB4187D218',
			},
			{
				label: 'North Macedonia',
				value: '+389',
				countryId: '332C7F88-B173-42A8-D2C0-08DB4187D218',
			},
			{
				label: 'Madagascar',
				value: '+261',
				countryId: 'BCFF16A7-2260-446A-D2C1-08DB4187D218',
			},
			{
				label: 'Malawi',
				value: '+265',
				countryId: '752A3576-671A-4CFC-D2C2-08DB4187D218',
			},
			{
				label: 'Malaysia',
				value: '+60',
				countryId: 'BECD7F00-15D2-496A-D2C3-08DB4187D218',
			},
			{
				label: 'Maldives',
				value: '+960',
				countryId: '54F71274-F53C-45E7-D2C4-08DB4187D218',
			},
			{
				label: 'Mali',
				value: '+223',
				countryId: 'A735EC46-0B6F-44D2-D2C5-08DB4187D218',
			},
			{
				label: 'Malta',
				value: '+356',
				countryId: '40143A38-651F-4E99-D2C6-08DB4187D218',
			},
			{
				label: 'Marshall Islands',
				value: '+692',
				countryId: '91E7C29D-BF43-4D3C-D2C7-08DB4187D218',
			},
			{
				label: 'Martinique',
				value: '+596',
				countryId: 'D8158B11-5B50-4902-D2C8-08DB4187D218',
			},
			{
				label: 'Mauritania',
				value: '+222',
				countryId: '99F98A99-0BD4-4023-D2C9-08DB4187D218',
			},
			{
				label: 'Mauritius',
				value: '+230',
				countryId: 'D1928618-93B3-4184-D2CA-08DB4187D218',
			},
			{
				label: 'Mayotte',
				value: '+262',
				countryId: '9A0C7DFF-6AC7-4A90-D2CB-08DB4187D218',
			},
			{
				label: 'Mexico',
				value: '+52',
				countryId: '2CF6CD80-7BD3-44AD-D2CC-08DB4187D218',
			},
			{
				label: 'Micronesia (Federated States of)',
				value: '+691',
				countryId: '804C5A0A-9CFA-47EF-D2CD-08DB4187D218',
			},
			{
				label: 'Moldova (Republic of)',
				value: '+373',
				countryId: 'B023F8A0-FE94-4A0A-D2CE-08DB4187D218',
			},
			{
				label: 'Monaco',
				value: '+377',
				countryId: '68C53216-86F5-49F6-D2CF-08DB4187D218',
			},
			{
				label: 'Mongolia',
				value: '+976',
				countryId: '357BFA5E-ABEC-4A47-D2D0-08DB4187D218',
			},
			{
				label: 'Montenegro',
				value: '+382',
				countryId: 'CFB6A498-6859-4298-D2D1-08DB4187D218',
			},
			{
				label: 'Montserrat',
				value: '+1',
				countryId: 'CDFB0127-3403-4B5F-D2D2-08DB4187D218',
			},
			{
				label: 'Morocco',
				value: '+212',
				countryId: '43DB5774-F9D6-4C01-D2D3-08DB4187D218',
			},
			{
				label: 'Mozambique',
				value: '+258',
				countryId: 'C5D587B0-C0BF-4480-D2D4-08DB4187D218',
			},
			{
				label: 'Myanmar',
				value: '+95',
				countryId: '8DFE3A6F-61A2-41C0-D2D5-08DB4187D218',
			},
			{
				label: 'Namibia',
				value: '+264',
				countryId: 'E6A4D89D-5055-420E-D2D6-08DB4187D218',
			},
			{
				label: 'Nauru',
				value: '+674',
				countryId: '7C8B8987-5792-4A06-D2D7-08DB4187D218',
			},
			{
				label: 'Nepal',
				value: '+977',
				countryId: 'DC5131CD-FC1B-42DC-D2D8-08DB4187D218',
			},
			{
				label: 'Netherlands',
				value: '+31',
				countryId: 'ABAE370E-6EC3-4843-D2D9-08DB4187D218',
			},
			{
				label: 'New Caledonia',
				value: '+687',
				countryId: 'E0A484EC-F24C-459A-D2DA-08DB4187D218',
			},
			{
				label: 'New Zealand',
				value: '+64',
				countryId: '1E7D0B85-2736-42AA-D2DB-08DB4187D218',
			},
			{
				label: 'Nicaragua',
				value: '+505',
				countryId: '8ED8B2C8-6108-42B9-D2DC-08DB4187D218',
			},
			{
				label: 'Niger',
				value: '+227',
				countryId: '7BB6A84A-DB81-4FE4-D2DD-08DB4187D218',
			},
			{
				label: 'Nigeria',
				value: '+234',
				countryId: '9B395EDD-4390-4192-D2DE-08DB4187D218',
			},
			{
				label: 'Niue',
				value: '+683',
				countryId: 'B6EBEF8B-21C8-4C9F-D2DF-08DB4187D218',
			},
			{
				label: 'Norfolk Island',
				value: '+672',
				countryId: 'E534129D-531B-4ADE-D2E0-08DB4187D218',
			},
			{
				label: 'Korea (Democratic Peoples Republic of)',
				value: '+850',
				countryId: '56362F74-AF94-4705-D2E1-08DB4187D218',
			},
			{
				label: 'Northern Mariana Islands',
				value: '+1',
				countryId: '70F464CD-FF9D-4A2E-D2E2-08DB4187D218',
			},
			{
				label: 'Norway',
				value: '+47',
				countryId: '32A35235-5765-43E2-D2E3-08DB4187D218',
			},
			{
				label: 'Oman',
				value: '+968',
				countryId: 'FE2395AB-8FA9-4168-D2E4-08DB4187D218',
			},
			{
				label: 'Pakistan',
				value: '+92',
				countryId: '77416E38-AD0F-491B-D2E5-08DB4187D218',
			},
			{
				label: 'Palau',
				value: '+680',
				countryId: 'B5136CD1-BFBD-4111-D2E6-08DB4187D218',
			},
			{
				label: 'Palestine, State of',
				value: '+970',
				countryId: 'DB319EC0-68F0-4BBE-D2E7-08DB4187D218',
			},
			{
				label: 'Panama',
				value: '+507',
				countryId: '2E7C6D34-CDBE-41B2-D2E8-08DB4187D218',
			},
			{
				label: 'Papua New Guinea',
				value: '+675',
				countryId: 'DD03A991-8C9C-4811-D2E9-08DB4187D218',
			},
			{
				label: 'Paraguay',
				value: '+595',
				countryId: 'E187BDE5-4B71-4B1E-D2EA-08DB4187D218',
			},
			{
				label: 'Peru',
				value: '+51',
				countryId: '8323BBB2-5E4F-4B77-D2EB-08DB4187D218',
			},
			{
				label: 'Philippines',
				value: '+63',
				countryId: '70C3DA0D-4382-444A-D2EC-08DB4187D218',
			},
			{
				label: 'Pitcairn',
				value: '+64',
				countryId: '6EBB7814-E06E-4E8C-D2ED-08DB4187D218',
			},
			{
				label: 'Poland',
				value: '+48',
				countryId: '834D8126-77F5-4917-D2EE-08DB4187D218',
			},
			{
				label: 'Portugal',
				value: '+351',
				countryId: '5DB62342-A0A2-4ABE-D2EF-08DB4187D218',
			},
			{
				label: 'Puerto Rico',
				value: '+1',
				countryId: 'D06F27C9-17A7-454F-D2F0-08DB4187D218',
			},
			{
				label: 'Qatar',
				value: '+974',
				countryId: '4122567A-D74F-42AE-D2F1-08DB4187D218',
			},
			{
				label: 'Republic of Kosovo',
				value: '+383',
				countryId: '15970680-60A4-426B-D2F2-08DB4187D218',
			},
			{
				label: 'Réunion',
				value: '+262',
				countryId: '17FC8A2A-29C3-49A0-D2F3-08DB4187D218',
			},
			{
				label: 'Romania',
				value: '+40',
				countryId: '5BAC968C-1B9F-4533-D2F4-08DB4187D218',
			},
			{
				label: 'Russian Federation',
				value: '+7',
				countryId: 'C401765D-0472-4CCA-D2F5-08DB4187D218',
			},
			{
				label: 'Rwanda',
				value: '+250',
				countryId: '349E5B7D-0ACE-4A17-D2F6-08DB4187D218',
			},
			{
				label: 'Saint Barthélemy',
				value: '+590',
				countryId: '6636E99D-2050-4B21-D2F7-08DB4187D218',
			},
			{
				label: 'Saint Helena, Ascension and Tristan da Cunha',
				value: '+290',
				countryId: 'E635008A-9588-439C-D2F8-08DB4187D218',
			},
			{
				label: 'Saint Kitts and Nevis',
				value: '+1',
				countryId: '3D11DE8D-7C1A-4B9E-D2F9-08DB4187D218',
			},
			{
				label: 'Saint Lucia',
				value: '+1',
				countryId: '14B84282-CD37-4499-D2FA-08DB4187D218',
			},
			{
				label: 'Saint Martin (French part)',
				value: '+590',
				countryId: 'A6CCF6CC-D851-456D-D2FB-08DB4187D218',
			},
			{
				label: 'Saint Pierre and Miquelon',
				value: '+508',
				countryId: 'E77AAC0D-BCEA-4CEB-D2FC-08DB4187D218',
			},
			{
				label: 'Saint Vincent and the Grenadines',
				value: '+1',
				countryId: 'E376241B-BD04-4856-D2FD-08DB4187D218',
			},
			{
				label: 'Samoa',
				value: '+685',
				countryId: '545AC79D-AE90-4765-D2FE-08DB4187D218',
			},
			{
				label: 'San Marino',
				value: '+378',
				countryId: '4817ADD7-5791-4E54-D2FF-08DB4187D218',
			},
			{
				label: 'Sao Tome and Principe',
				value: '+239',
				countryId: '00DC10C6-1738-475D-D300-08DB4187D218',
			},
			{
				label: 'Saudi Arabia',
				value: '+966',
				countryId: '6EDED8A7-1F10-45DE-D301-08DB4187D218',
			},
			{
				label: 'Senegal',
				value: '+221',
				countryId: 'D40203AE-FFDD-464D-D302-08DB4187D218',
			},
			{
				label: 'Serbia',
				value: '+381',
				countryId: '4F0D7B5C-A17F-402F-D303-08DB4187D218',
			},
			{
				label: 'Seychelles',
				value: '+248',
				countryId: '31063D9B-15DB-4FA0-D304-08DB4187D218',
			},
			{
				label: 'Sierra Leone',
				value: '+232',
				countryId: 'EED64D6B-0D15-4AD3-D305-08DB4187D218',
			},
			{
				label: 'Singapore',
				value: '+65',
				countryId: '5B45542F-7838-4ED5-D306-08DB4187D218',
			},
			{
				label: 'Sint Maarten (Dutch part)',
				value: '+1',
				countryId: '4737EFE1-9325-450D-D307-08DB4187D218',
			},
			{
				label: 'Slovakia',
				value: '+421',
				countryId: 'EFBB6094-77F5-48C8-D308-08DB4187D218',
			},
			{
				label: 'Slovenia',
				value: '+386',
				countryId: 'F2BF5697-4F82-4C59-D309-08DB4187D218',
			},
			{
				label: 'Solomon Islands',
				value: '+677',
				countryId: 'F325D66E-06F8-43F9-D30A-08DB4187D218',
			},
			{
				label: 'Somalia',
				value: '+252',
				countryId: 'DD939F3D-CCB7-47B9-D30B-08DB4187D218',
			},
			{
				label: 'South Africa',
				value: '+27',
				countryId: '155FA8D9-421A-4822-D30C-08DB4187D218',
			},
			{
				label: 'South Georgia and the South Sandwich Islands',
				value: '+500',
				countryId: '4C6F50B4-A939-403B-D30D-08DB4187D218',
			},
			{
				label: 'Korea (Republic of)',
				value: '+82',
				countryId: '00DED49E-4BA3-4A98-D30E-08DB4187D218',
			},
			{
				label: 'Spain',
				value: '+34',
				countryId: '38C7E530-1998-41FC-D30F-08DB4187D218',
			},
			{
				label: 'Sri Lanka',
				value: '+94',
				countryId: 'E79AF373-7593-47F2-D310-08DB4187D218',
			},
			{
				label: 'Sudan',
				value: '+249',
				countryId: 'F4E4FE82-F184-459D-D311-08DB4187D218',
			},
			{
				label: 'South Sudan',
				value: '+211',
				countryId: 'C06B9CC7-8043-4B87-D312-08DB4187D218',
			},
			{
				label: 'Suriname',
				value: '+597',
				countryId: 'D5020C5B-59D4-4E36-D313-08DB4187D218',
			},
			{
				label: 'Svalbard and Jan Mayen',
				value: '+47',
				countryId: 'BE816503-BA14-4848-D314-08DB4187D218',
			},
			{
				label: 'Swaziland',
				value: '+268',
				countryId: '295FC289-9A0C-4E99-D315-08DB4187D218',
			},
			{
				label: 'Sweden',
				value: '+46',
				countryId: '7FC29E32-B070-4A8C-D316-08DB4187D218',
			},
			{
				label: 'Switzerland',
				value: '+41',
				countryId: '1C825BAE-BFE0-4E45-D317-08DB4187D218',
			},
			{
				label: 'Syrian Arab Republic',
				value: '+963',
				countryId: '29397097-29FE-4BB2-D318-08DB4187D218',
			},
			{
				label: 'Taiwan',
				value: '+886',
				countryId: 'AE6B6419-AAE1-48A8-D319-08DB4187D218',
			},
			{
				label: 'Tajikistan',
				value: '+992',
				countryId: 'B962C23A-168A-4858-D31A-08DB4187D218',
			},
			{
				label: 'Tanzania, United Republic of',
				value: '+255',
				countryId: 'C92353FF-42A3-4E35-D31B-08DB4187D218',
			},
			{
				label: 'Thailand',
				value: '+66',
				countryId: 'B7822BC7-65F8-444E-D31C-08DB4187D218',
			},
			{
				label: 'Timor-Leste',
				value: '+670',
				countryId: '3E8C146C-75D2-4D1D-D31D-08DB4187D218',
			},
			{
				label: 'Togo',
				value: '+228',
				countryId: 'B19B530D-F1CE-47E4-D31E-08DB4187D218',
			},
			{
				label: 'Tokelau',
				value: '+690',
				countryId: '5A2398AA-59EB-424D-D31F-08DB4187D218',
			},
			{
				label: 'Tonga',
				value: '+676',
				countryId: '7918834A-0AF0-4E0B-D320-08DB4187D218',
			},
			{
				label: 'Trinidad and Tobago',
				value: '+1',
				countryId: '89AB3E72-BA57-4137-D321-08DB4187D218',
			},
			{
				label: 'Tunisia',
				value: '+216',
				countryId: '045E82F0-B710-4E34-D322-08DB4187D218',
			},
			{
				label: 'Turkey',
				value: '+90',
				countryId: '59F1511A-0E4F-4F51-D323-08DB4187D218',
			},
			{
				label: 'Turkmenistan',
				value: '+993',
				countryId: '33B0C23C-FB7E-4DC6-D324-08DB4187D218',
			},
			{
				label: 'Turks and Caicos Islands',
				value: '+1',
				countryId: '51137F41-FF64-4DE8-D325-08DB4187D218',
			},
			{
				label: 'Tuvalu',
				value: '+688',
				countryId: '74EA4C9B-A071-487F-D326-08DB4187D218',
			},
			{
				label: 'Uganda',
				value: '+256',
				countryId: '2EC0AF7D-E3B4-4EEC-D327-08DB4187D218',
			},
			{
				label: 'Ukraine',
				value: '+380',
				countryId: '32766B0B-8910-445E-D328-08DB4187D218',
			},
			{
				label: 'United Arab Emirates',
				value: '+971',
				countryId: 'E2E0F6AC-9E21-4D92-D329-08DB4187D218',
			},
			{
				label: 'United Kingdom of Great Britain and Northern Ireland',
				value: '+44',
				countryId: '5ECC09A2-86A1-473F-D32A-08DB4187D218',
			},
			{
				label: 'United States of America',
				value: '+1',
				countryId: '6611C245-CF8E-4B78-D32B-08DB4187D218',
			},
			{
				label: 'Uruguay',
				value: '+598',
				countryId: '1464C41D-7B20-4044-D32C-08DB4187D218',
			},
			{
				label: 'Uzbekistan',
				value: '+998',
				countryId: '14BA5FA2-DE2A-4F4E-D32D-08DB4187D218',
			},
			{
				label: 'Vanuatu',
				value: '+678',
				countryId: '6F567CCD-F1EF-4AC5-D32E-08DB4187D218',
			},
			{
				label: 'Venezuela (Bolivarian Republic of)',
				value: '+58',
				countryId: '1AA82E8A-6041-4354-D32F-08DB4187D218',
			},
			{
				label: 'Vietnam',
				value: '+84',
				countryId: '358EEB51-AD5A-439A-D330-08DB4187D218',
			},
			{
				label: 'Wallis and Futuna',
				value: '+681',
				countryId: '77002FB5-E22E-4E68-D331-08DB4187D218',
			},
			{
				label: 'Western Sahara',
				value: '+212',
				countryId: '80C88B77-227D-4A58-D332-08DB4187D218',
			},
			{
				label: 'Yemen',
				value: '+967',
				countryId: 'E7D5F9CF-49A8-4F85-D333-08DB4187D218',
			},
			{
				label: 'Zambia',
				value: '+260',
				countryId: '66242D21-081C-4713-D334-08DB4187D218',
			},
			{
				label: 'Zimbabwe',
				value: '+263',
				countryId: '3243E7D0-028B-49A9-D335-08DB4187D218',
			}
		]
	},
	{
		lang: 'hr',
		data: [
			{
				label: 'Afganistan',
				value: '+93',
				countryId: '1B09274B-01B1-42D3-D23C-08DB4187D218'
			},
			{
				label: 'Ålandski otoci',
				value: '+358',
				countryId: 'CC83162E-61A2-4C2C-D23D-08DB4187D218'
			},
			{
				label: 'Albanija',
				value: '+355',
				countryId: 'EF40F256-C64F-4DBC-D23E-08DB4187D218'
			},
			{
				label: 'Alžir',
				value: '+213',
				countryId: 'DF845A91-198C-4858-D23F-08DB4187D218'
			},
			{
				label: 'Američka Samoa',
				value: '+1',
				countryId: 'E1639038-BDE3-4993-D240-08DB4187D218'
			},
			{
				label: 'Andora',
				value: '+376',
				countryId: '4A8763CD-0165-43F6-D241-08DB4187D218'
			},
			{
				label: 'Angola',
				value: '+244',
				countryId: '2E620669-4E42-468F-D242-08DB4187D218'
			},
			{
				label: 'Angvila',
				value: '+1',
				countryId: '3AC88E68-F7A1-4C75-D243-08DB4187D218'
			},
			{
				label: 'Antarktika',
				value: '+672',
				countryId: 'A3185321-A629-4BC6-D244-08DB4187D218'
			},
			{
				label: 'Antigva i Barbuda',
				value: '+1',
				countryId: '28D61ED5-CDD7-4AC4-D245-08DB4187D218'
			},
			{
				label: 'Argentina',
				value: '+54',
				countryId: '1FAD35E2-EE0F-449B-D246-08DB4187D218'
			},
			{
				label: 'Armenija',
				value: '+374',
				countryId: '9F978A84-682B-4864-D247-08DB4187D218'
			},
			{
				label: 'Aruba',
				value: '+297',
				countryId: 'EF35A40E-DBEB-45CA-D248-08DB4187D218'
			},
			{
				label: 'Australija',
				value: '+61',
				countryId: '6CA6364C-34CE-4B40-D249-08DB4187D218'
			},
			{
				label: 'Austrija',
				value: '+43',
				countryId: 'CBB38F8C-5EA8-4ABF-D24A-08DB4187D218'
			},
			{
				label: 'Azerbejdžan',
				value: '+994',
				countryId: '0D74391A-64E0-48F3-D24B-08DB4187D218'
			},
			{
				label: 'Bahami',
				value: '+1',
				countryId: '45FE54CB-9D7B-47A2-D24C-08DB4187D218'
			},
			{
				label: 'Bahrein',
				value: '+973',
				countryId: '19007079-1F3D-47C5-D24D-08DB4187D218'
			},
			{
				label: 'Bangladeš',
				value: '+880',
				countryId: '8E56DC92-B1C9-4592-D24E-08DB4187D218'
			},
			{
				label: 'Barbados',
				value: '+1',
				countryId: 'B675C7E6-CA19-4F77-D24F-08DB4187D218'
			},
			{
				label: 'Bjelorusija',
				value: '+375',
				countryId: 'B6862AC3-FA76-4352-D250-08DB4187D218'
			},
			{
				label: 'Belgija',
				value: '+32',
				countryId: 'F6B6E355-5739-4A61-D251-08DB4187D218'
			},
			{
				label: 'Belize',
				value: '+501',
				countryId: 'FD5FB6ED-8C78-4103-D252-08DB4187D218'
			},
			{
				label: 'Benin',
				value: '+229',
				countryId: '6C6C81EE-9DC1-4C93-D253-08DB4187D218'
			},
			{
				label: 'Bermuda',
				value: '+1',
				countryId: '82E4C244-69DA-45A4-D254-08DB4187D218'
			},
			{
				label: 'Butan',
				value: '+975',
				countryId: 'D4929593-E0CD-427A-D255-08DB4187D218'
			},
			{
				label: 'Bolivija (Plurinacionalna Država)',
				value: '+591',
				countryId: '46625A1B-0564-41A9-D256-08DB4187D218'
			},
			{
				label: 'Bonaire, Sint Eustatius i Saba',
				value: '+599',
				countryId: '2C4D70D4-4C23-4066-D257-08DB4187D218'
			},
			{
				label: 'Bosna i Hercegovina',
				value: '+387',
				countryId: '5BEABFDC-745C-41D9-D258-08DB4187D218'
			},
			{
				label: 'Bocvana',
				value: '+267',
				countryId: 'FEF34A6F-D555-456D-D259-08DB4187D218'
			},
			{
				label: 'Otok Bouvet',
				value: '+47',
				countryId: 'A8CC326B-DCEF-43AB-D25A-08DB4187D218'
			},
			{
				label: 'Brazil',
				value: '+55',
				countryId: '809F4023-6033-426C-D25B-08DB4187D218'
			},
			{
				label: 'Britanski Indijskooceanski Teritorij',
				value: '+246',
				countryId: '8EA884E8-5694-4C44-D25C-08DB4187D218',
			},
			{
				label: 'Sjedinjene Američke Otočne Države',
				value: '+246',
				countryId: 'E416F286-4EBE-4ACF-D25D-08DB4187D218'
			},
			{
				label: 'Djevičanski Otoci (Britanski)',
				value: '+1',
				countryId: 'FD484869-FE2B-4C0F-D25E-08DB4187D218'
			},
			{
				label: 'Djevičanski Otoci (SAD)',
				value: '+1 340',
				countryId: '5DFB7435-068C-4E57-D25F-08DB4187D218'
			},
			{
				label: 'Brunej',
				value: '+673',
				countryId: '34317268-4377-44E1-D260-08DB4187D218'
			},
			{
				label: 'Bugarska',
				value: '+359',
				countryId: '309DC380-B6D6-4F63-D261-08DB4187D218'
			},
			{
				label: 'Burkina Faso',
				value: '+226',
				countryId: 'DACEB0BC-0ACD-4EF8-D262-08DB4187D218'
			},
			{
				label: 'Burundi',
				value: '+257',
				countryId: '63F42FF5-27E9-41B8-D263-08DB4187D218'
			},
			{
				label: 'Kambodža',
				value: '+855',
				countryId: '8BB33699-1E92-418D-D264-08DB4187D218'
			},
			{
				label: 'Kamerun',
				value: '+237',
				countryId: '17AFF4AA-8DA4-403F-D265-08DB4187D218'
			},
			{
				label: 'Kanada',
				value: '+1',
				countryId: '25F4EC32-AD21-4E0F-D266-08DB4187D218'
			},
			{
				label: 'Zelenortska Ostrva',
				value: '+238',
				countryId: '15203231-F793-47B7-D267-08DB4187D218'
			},
			{
				label: 'Kajmanski Otoci',
				value: '+1',
				countryId: '7E44AD12-351B-4499-D268-08DB4187D218'
			},
			{
				label: 'Srednjoafrička Republika',
				value: '+236',
				countryId: 'D6AD317E-1768-4755-D269-08DB4187D218'
			},
			{
				label: 'Čad',
				value: '+235',
				countryId: 'D12A58C7-7838-4B4F-D26A-08DB4187D218'
			},
			{
				label: 'Čile',
				value: '+56',
				countryId: 'B3B11221-2530-4FB9-D26B-08DB4187D218'
			},
			{
				label: 'Kina',
				value: '+86',
				countryId: '9B69BDDB-9B9B-40FB-D26C-08DB4187D218'
			},
			{
				label: 'Božićni Otok',
				value: '+61',
				countryId: '1525E435-647C-43E0-D26D-08DB4187D218'
			},
			{
				label: 'Kokosovi (Keeling) Otoci',
				value: '+61',
				countryId: '62896E7E-CA0D-438B-D26E-08DB4187D218'
			},
			{
				label: 'Kolumbija',
				value: '+57',
				countryId: '75280D5A-0CF2-46EE-D26F-08DB4187D218'
			},
			{
				label: 'Komori',
				value: '+269',
				countryId: '668BA360-7AB7-4E36-D270-08DB4187D218'
			},
			{
				label: 'Kongo',
				value: '+242',
				countryId: '2279885C-E4DD-4DFF-D271-08DB4187D218'
			},
			{
				label: 'Kongo (Demokratska Republika)',
				value: '+243',
				countryId: '8773BCE1-2D1E-4324-D272-08DB4187D218'
			},
			{
				label: 'Cookovi Otoci',
				value: '+682',
				countryId: 'A4E85595-C6A1-4DB7-D273-08DB4187D218'
			},
			{
				label: 'Kostarika',
				value: '+506',
				countryId: '4751E799-9F8A-49C1-D274-08DB4187D218'
			},
			{
				label: 'Hrvatska',
				value: '+385',
				countryId: '361DF93A-C799-4B42-D275-08DB4187D218'
			},
			{
				label: 'Kuba',
				value: '+53',
				countryId: '0915AA13-5CF4-48C8-D276-08DB4187D218'
			},
			{
				label: 'Curaçao',
				value: '+599',
				countryId: '4B51797D-7977-4CA4-D277-08DB4187D218'
			},
			{
				label: 'Cipar',
				value: '+357',
				countryId: 'C8A504B7-37F9-4EE9-D278-08DB4187D218'
			},
			{
				label: 'Češka Republika',
				value: '+420',
				countryId: '6B4067BD-BA67-4037-D279-08DB4187D218'
			},
			{
				label: 'Danska',
				value: '+45',
				countryId: '2F4BFB26-36A5-4ABD-D27A-08DB4187D218'
			},
			{
				label: 'Džibuti',
				value: '+253',
				countryId: 'ABC20812-3367-40AF-D27B-08DB4187D218'
			},
			{
				label: 'Dominika',
				value: '+1',
				countryId: '08AA6F10-050F-47A8-D27C-08DB4187D218'
			},
			{
				label: 'Dominikanska Republika',
				value: '+1',
				countryId: 'DAA52F5F-8B24-4D4B-D27D-08DB4187D218'
			},
			{
				label: 'Ekvador',
				value: '+593',
				countryId: '8CB0A48C-C88E-4541-D27E-08DB4187D218'
			},
			{
				label: 'Egipat',
				value: '+20',
				countryId: '5BB3D720-C2D3-4288-D27F-08DB4187D218'
			},
				{
				label: 'Salvador',
				value: '+503',
				countryId: '3C979B68-9D7B-4FF6-D280-08DB4187D218'
			},
			{
				label: 'Ekvatorska Gvineja',
				value: '+240',
				countryId: 'D07F42AA-3489-4AA3-D281-08DB4187D218'
			},
			{
				label: 'Eritreja',
				value: '+291',
				countryId: '18D391F9-75DA-42FE-D282-08DB4187D218'
			},
			{
				label: 'Estonija',
				value: '+372',
				countryId: '9E97A3BB-EE5D-4BC1-D283-08DB4187D218'
			},
			{
				label: 'Etiopija',
				value: '+251',
				countryId: '7B97981D-B489-48D6-D284-08DB4187D218'
			},
			{
				label: 'Falklandski Otoci (Malvini)',
				value: '+500',
				countryId: '4FDEC645-8059-442D-D285-08DB4187D218'
			},
			{
				label: 'Farski Otoci',
				value: '+298',
				countryId: '1CD78B10-2A61-46A1-D286-08DB4187D218'
			},
			{
				label: 'Fidži',
				value: '+679',
				countryId: '725189E7-0326-4A14-D287-08DB4187D218'
			},
			{
				label: 'Finska',
				value: '+358',
				countryId: 'DA0C0248-69DA-4D6E-D288-08DB4187D218'
			},
			{
				label: 'Francuska',
				value: '+33',
				countryId: '23DB6CF5-B064-4F1E-D289-08DB4187D218'
			},
			{
				label: 'Francuska Gvajana',
				value: '+594',
				countryId: '1AE42273-48D4-46CF-D28A-08DB4187D218'
			},
			{
				label: 'Francuska Polinezija',
				value: '+689',
				countryId: 'F5259A6C-7A92-4BA9-D28B-08DB4187D218'
			},
			{
				label: 'Francuski Južni Teritoriji',
				value: '+262',
				countryId: 'B60527BA-FFE5-4D79-D28C-08DB4187D218'
			},
			{
				label: 'Gabon',
				value: '+241',
				countryId: 'EE8559E2-83D4-49D9-D28D-08DB4187D218'
			},
			{
				label: 'Gambija',
				value: '+220',
				countryId: 'E3121FF7-82B2-497E-D28E-08DB4187D218'
			},
			{
				label: 'Gruzija',
				value: '+995',
				countryId: 'E82D2BE9-7CC9-4CBB-D28F-08DB4187D218'
			},
			{
				label: 'Njemačka',
				value: '+49',
				countryId: '8D05C85E-189A-4FCA-D290-08DB4187D218'
			},
				{
				label: 'Gana',
				value: '+233',
				countryId: '8E439BA0-EA86-4037-D291-08DB4187D218'
			},
			{
				label: 'Gibraltar',
				value: '+350',
				countryId: '76812F36-B39F-477A-D292-08DB4187D218'
			},
			{
				label: 'Grčka',
				value: '+30',
				countryId: '427102DA-FDF2-4B60-D293-08DB4187D218'
			},
			{
				label: 'Grenland',
				value: '+299',
				countryId: '2657765E-9060-414F-D294-08DB4187D218'
			},
			{
				label: 'Grenada',
				value: '+1',
				countryId: '2544DD30-EE13-424D-D295-08DB4187D218'
			},
			{
				label: 'Gvadalupe',
				value: '+590',
				countryId: '38B06F3F-9DCE-4807-D296-08DB4187D218'
			},
			{
				label: 'Guam',
				value: '+1',
				countryId: 'E98775F2-CC2E-4240-D297-08DB4187D218'
			},
			{
				label: 'Gvatemala',
				value: '+502',
				countryId: '752945A5-39F0-4985-D298-08DB4187D218'
			},
			{
				label: 'Guernsey',
				value: '+44',
				countryId: '85CC5DE3-81D4-44E8-D299-08DB4187D218'
			},
			{
				label: 'Gvineja',
				value: '+224',
				countryId: '834F5902-6A03-4928-D29A-08DB4187D218'
			},
			{
				label: 'Gvineja-Bissau',
				value: '+245',
				countryId: '5B5B6788-6300-4656-D29B-08DB4187D218'
			},
			{
				label: 'Gvajana',
				value: '+592',
				countryId: '700346CE-EC9D-43C7-D29C-08DB4187D218'
			},
			{
				label: 'Haiti',
				value: '+509',
				countryId: 'F21838D8-FA10-486D-D29D-08DB4187D218'
			},
			{
				label: 'Otok Heard i Otoci McDonald',
				value: '+672',
				countryId: '6D9D8F41-BCE8-4A63-D29E-08DB4187D218'
			},
			{
				label: 'Vatikan',
				value: '+379',
				countryId: '206260A5-B5D1-4CAF-D29F-08DB4187D218'
			},
			{
				label: 'Honduras',
				value: '+504',
				countryId: '4789FF76-9F84-477E-D2A0-08DB4187D218'
			},
			{
				label: 'Mađarska',
				value: '+36',
				countryId: '3FBB6A91-AD4F-47B2-D2A1-08DB4187D218'
			},
			{
				label: 'Hong Kong',
				value: '+852',
				countryId: 'EAA8EFBC-3D64-449D-D2A2-08DB4187D218'
			},
				{
				label: 'Island',
				value: '+354',
				countryId: '61FE256A-FF4F-493E-D2A3-08DB4187D218'
			},
			{
				label: 'Indija',
				value: '+91',
				countryId: '79A4ACA8-ECBE-428B-D2A4-08DB4187D218'
			},
			{
				label: 'Indonezija',
				value: '+62',
				countryId: '67750109-EABF-4D4D-D2A5-08DB4187D218'
			},
			{
				label: 'Obala Bjelokosti',
				value: '+225',
				countryId: '176C9359-C50D-4443-D2A6-08DB4187D218'
			},
			{
				label: 'Iran (Islamska Republika)',
				value: '+98',
				countryId: '928E7D24-DCF3-4534-D2A7-08DB4187D218'
			},
			{
				label: 'Irak',
				value: '+964',
				countryId: '71D166CF-5BD4-404D-D2A8-08DB4187D218'
			},
			{
				label: 'Irska',
				value: '+353',
				countryId: '93EFB762-34F0-43AD-D2A9-08DB4187D218'
			},
			{
				label: 'Otok Man',
				value: '+44',
				countryId: '54758AB6-2597-41BB-D2AA-08DB4187D218'
			},
			{
				label: 'Izrael',
				value: '+972',
				countryId: '57109F72-C10B-484F-D2AB-08DB4187D218'
			},
			{
				label: 'Italija',
				value: '+39',
				countryId: '4ED0F94A-97E5-4757-D2AC-08DB4187D218'
			},
			{
				label: 'Jamajka',
				value: '+1',
				countryId: '46BE78C4-3F21-4273-D2AD-08DB4187D218'
			},
			{
				label: 'Japan',
				value: '+81',
				countryId: 'B363379A-CABE-4667-D2AE-08DB4187D218'
			},
			{
				label: 'Jersey',
				value: '+44',
				countryId: '2414F354-7623-4C46-D2AF-08DB4187D218'
			},
			{
				label: 'Jordan',
				value: '+962',
				countryId: '044B8B40-5D7B-4E23-D2B0-08DB4187D218'
			},
			{
				label: 'Kazahstan',
				value: '+76',
				countryId: '5EE7E7D0-A9CA-4477-D2B1-08DB4187D218'
			},
			{
				label: 'Kenija',
				value: '+254',
				countryId: '6EFCB7CF-68C2-4D2E-D2B2-08DB4187D218'
			},
			{
				label: 'Kiribati',
				value: '+686',
				countryId: '19B77088-EDD7-403A-D2B3-08DB4187D218'
			},
			{
				label: 'Kuvajt',
				value: '+965',
				countryId: '2D2D11AD-D154-4379-D2B4-08DB4187D218'
			},
				{
				label: 'Kirgistan',
				value: '+996',
				countryId: '0B574E0A-7E18-4EE5-D2B5-08DB4187D218'
			},
			{
				label: 'Laos',
				value: '+856',
				countryId: '83CA3A51-2B16-47AE-D2B6-08DB4187D218'
			},
			{
				label: 'Latvija',
				value: '+371',
				countryId: 'F4007A8A-3037-497C-D2B7-08DB4187D218'
			},
			{
				label: 'Libanon',
				value: '+961',
				countryId: '15EDB12D-FACB-4360-D2B8-08DB4187D218'
			},
			{
				label: 'Lesoto',
				value: '+266',
				countryId: 'CB8D3527-C786-4C18-D2B9-08DB4187D218'
			},
			{
				label: 'Liberija',
				value: '+231',
				countryId: 'C78F54CD-B8C2-401D-D2BA-08DB4187D218'
			},
			{
				label: 'Libija',
				value: '+218',
				countryId: '9F25A005-5922-4DEB-D2BB-08DB4187D218'
			},
			{
				label: 'Lihtenštajn',
				value: '+423',
				countryId: '9A7A3629-A123-45CF-D2BC-08DB4187D218'
			},
			{
				label: 'Litva',
				value: '+370',
				countryId: 'CC831D13-CA5A-4FEA-D2BD-08DB4187D218'
			},
			{
				label: 'Luksemburg',
				value: '+352',
				countryId: '9EE9CD41-26CF-45AF-D2BE-08DB4187D218'
			},
			{
				label: 'Makao',
				value: '+853',
				countryId: '7530B578-1950-4672-D2BF-08DB4187D218'
			},
			{
				label: 'Sjeverna Makedonija',
				value: '+389',
				countryId: '332C7F88-B173-42A8-D2C0-08DB4187D218'
			},
			{
				label: 'Madagaskar',
				value: '+261',
				countryId: 'BCFF16A7-2260-446A-D2C1-08DB4187D218'
			},
			{
				label: 'Malavi',
				value: '+265',
				countryId: '752A3576-671A-4CFC-D2C2-08DB4187D218'
			},
			{
				label: 'Malezija',
				value: '+60',
				countryId: 'BECD7F00-15D2-496A-D2C3-08DB4187D218'
			},
			{
				label: 'Maldivi',
				value: '+960',
				countryId: '54F71274-F53C-45E7-D2C4-08DB4187D218'
			},
			{
				label: 'Mali',
				value: '+223',
				countryId: 'A735EC46-0B6F-44D2-D2C5-08DB4187D218'
			},
			{
				label: 'Malta',
				value: '+356',
				countryId: '40143A38-651F-4E99-D2C6-08DB4187D218'
			},
				{
				label: 'Maršalovi Otoci',
				value: '+692',
				countryId: '91E7C29D-BF43-4D3C-D2C7-08DB4187D218'
			},
			{
				label: 'Martinik',
				value: '+596',
				countryId: 'D8158B11-5B50-4902-D2C8-08DB4187D218'
			},
			{
				label: 'Mauritanija',
				value: '+222',
				countryId: '99F98A99-0BD4-4023-D2C9-08DB4187D218'
			},
			{
				label: 'Mauricijus',
				value: '+230',
				countryId: 'D1928618-93B3-4184-D2CA-08DB4187D218'
			},
			{
				label: 'Mayotte',
				value: '+262',
				countryId: '9A0C7DFF-6AC7-4A90-D2CB-08DB4187D218'
			},
			{
				label: 'Meksiko',
				value: '+52',
				countryId: '2CF6CD80-7BD3-44AD-D2CC-08DB4187D218'
			},
			{
				label: 'Mikronezija (Federativne Države)',
				value: '+691',
				countryId: '804C5A0A-9CFA-47EF-D2CD-08DB4187D218'
			},
			{
				label: 'Moldavija (Republika)',
				value: '+373',
				countryId: 'B023F8A0-FE94-4A0A-D2CE-08DB4187D218'
			},
			{
				label: 'Monako',
				value: '+377',
				countryId: '68C53216-86F5-49F6-D2CF-08DB4187D218'
			},
			{
				label: 'Mongolija',
				value: '+976',
				countryId: '357BFA5E-ABEC-4A47-D2D0-08DB4187D218'
			},
			{
				label: 'Crna Gora',
				value: '+382',
				countryId: 'CFB6A498-6859-4298-D2D1-08DB4187D218'
			},
			{
				label: 'Montserrat',
				value: '+1',
				countryId: 'CDFB0127-3403-4B5F-D2D2-08DB4187D218'
			},
			{
				label: 'Maroko',
				value: '+212',
				countryId: '43DB5774-F9D6-4C01-D2D3-08DB4187D218'
			},
			{
				label: 'Mozambik',
				value: '+258',
				countryId: 'C5D587B0-C0BF-4480-D2D4-08DB4187D218'
			},
			{
				label: 'Mjanmar',
				value: '+95',
				countryId: '8DFE3A6F-61A2-41C0-D2D5-08DB4187D218'
			},
				{
				label: 'Namibia',
				value: '+264',
				countryId: 'E6A4D89D-5055-420E-D2D6-08DB4187D218'
			},
			{
				label: 'Nauru',
				value: '+674',
				countryId: '7C8B8987-5792-4A06-D2D7-08DB4187D218'
			},
			{
				label: 'Nepal',
				value: '+977',
				countryId: 'DC5131CD-FC1B-42DC-D2D8-08DB4187D218'
			},
			{
				label: 'Nizozemska',
				value: '+31',
				countryId: 'ABAE370E-6EC3-4843-D2D9-08DB4187D218'
			},
			{
				label: 'Nova Kaledonija',
				value: '+687',
				countryId: 'E0A484EC-F24C-459A-D2DA-08DB4187D218'
			},
			{
				label: 'Novi Zeland',
				value: '+64',
				countryId: '1E7D0B85-2736-42AA-D2DB-08DB4187D218'
			},
			{
				label: 'Nikaragva',
				value: '+505',
				countryId: '8ED8B2C8-6108-42B9-D2DC-08DB4187D218'
			},
			{
				label: 'Niger',
				value: '+227',
				countryId: '7BB6A84A-DB81-4FE4-D2DD-08DB4187D218'
			},
			{
				label: 'Nigerija',
				value: '+234',
				countryId: '9B395EDD-4390-4192-D2DE-08DB4187D218'
			},
			{
				label: 'Niue',
				value: '+683',
				countryId: 'B6EBEF8B-21C8-4C9F-D2DF-08DB4187D218'
			},
			{
				label: 'Otok Norfolk',
				value: '+672',
				countryId: 'E534129D-531B-4ADE-D2E0-08DB4187D218'
			},
			{
				label: 'Koreja (Narodna Republika)',
				value: '+850',
				countryId: '56362F74-AF94-4705-D2E1-08DB4187D218'
			},
			{
				label: 'Sjevernomarijanski otoci',
				value: '+1',
				countryId: '70F464CD-FF9D-4A2E-D2E2-08DB4187D218'
			},
			{
				label: 'Norveška',
				value: '+47',
				countryId: '32A35235-5765-43E2-D2E3-08DB4187D218'
			},
			{
				label: 'Oman',
				value: '+968',
				countryId: 'FE2395AB-8FA9-4168-D2E4-08DB4187D218'
			},
			{
				label: 'Pakistan',
				value: '+92',
				countryId: '77416E38-AD0F-491B-D2E5-08DB4187D218'
			},
			{
				label: 'Palau',
				value: '+680',
				countryId: 'B5136CD1-BFBD-4111-D2E6-08DB4187D218'
			},
			{
				label: 'Palestina, Država',
				value: '+970',
				countryId: 'DB319EC0-68F0-4BBE-D2E7-08DB4187D218'
			},
				{
				label: 'Panama',
				value: '+507',
				countryId: '2E7C6D34-CDBE-41B2-D2E8-08DB4187D218'
			},
			{
				label: 'Papua Nova Gvineja',
				value: '+675',
				countryId: 'DD03A991-8C9C-4811-D2E9-08DB4187D218'
			},
			{
				label: 'Paragvaj',
				value: '+595',
				countryId: 'E187BDE5-4B71-4B1E-D2EA-08DB4187D218'
			},
			{
				label: 'Peru',
				value: '+51',
				countryId: '8323BBB2-5E4F-4B77-D2EB-08DB4187D218'
			},
			{
				label: 'Filipini',
				value: '+63',
				countryId: '70C3DA0D-4382-444A-D2EC-08DB4187D218'
			},
			{
				label: 'Pitcairn',
				value: '+64',
				countryId: '6EBB7814-E06E-4E8C-D2ED-08DB4187D218'
			},
			{
				label: 'Poljska',
				value: '+48',
				countryId: '834D8126-77F5-4917-D2EE-08DB4187D218'
			},
			{
				label: 'Portugal',
				value: '+351',
				countryId: '5DB62342-A0A2-4ABE-D2EF-08DB4187D218'
			},
			{
				label: 'Portoriko',
				value: '+1',
				countryId: 'D06F27C9-17A7-454F-D2F0-08DB4187D218'
			},
			{
				label: 'Katar',
				value: '+974',
				countryId: '4122567A-D74F-42AE-D2F1-08DB4187D218'
			},
			{
				label: 'Republika Kosovo',
				value: '+383',
				countryId: '15970680-60A4-426B-D2F2-08DB4187D218'
			},
			{
				label: 'Réunion',
				value: '+262',
				countryId: '17FC8A2A-29C3-49A0-D2F3-08DB4187D218'
			},
			{
				label: 'Rumunjska',
				value: '+40',
				countryId: '5BAC968C-1B9F-4533-D2F4-08DB4187D218'
			},
				{
				label: 'Ruska Federacija',
				value: '+7',
				countryId: 'C401765D-0472-4CCA-D2F5-08DB4187D218'
			},
			{
				label: 'Ruanda',
				value: '+250',
				countryId: '349E5B7D-0ACE-4A17-D2F6-08DB4187D218'
			},
			{
				label: 'Sveti Bartolomej',
				value: '+590',
				countryId: '6636E99D-2050-4B21-D2F7-08DB4187D218'
			},
			{
				label: 'Sveta Helena, Ascension i Tristan da Cunha',
				value: '+290',
				countryId: 'E635008A-9588-439C-D2F8-08DB4187D218'
			},
			{
				label: 'Sveti Kits i Nevis',
				value: '+1',
				countryId: '3D11DE8D-7C1A-4B9E-D2F9-08DB4187D218'
			},
			{
				label: 'Sveti Lucija',
				value: '+1',
				countryId: '14B84282-CD37-4499-D2FA-08DB4187D218'
			},
			{
				label: 'Sveti Martin (Francuski dio)',
				value: '+590',
				countryId: 'A6CCF6CC-D851-456D-D2FB-08DB4187D218'
			},
			{
				label: 'Sveti Pjero i Mikelon',
				value: '+508',
				countryId: 'E77AAC0D-BCEA-4CEB-D2FC-08DB4187D218'
			},
			{
				label: 'Sveti Vinsent i Grenadini',
				value: '+1',
				countryId: 'E376241B-BD04-4856-D2FD-08DB4187D218'
			},
			{
				label: 'Samoa',
				value: '+685',
				countryId: '545AC79D-AE90-4765-D2FE-08DB4187D218'
			},
			{
				label: 'San Marino',
				value: '+378',
				countryId: '4817ADD7-5791-4E54-D2FF-08DB4187D218'
			},
			{
				label: 'Sao Tome i Principe',
				value: '+239',
				countryId: '00DC10C6-1738-475D-D300-08DB4187D218'
			},
			{
				label: 'Saudijska Arabija',
				value: '+966',
				countryId: '6EDED8A7-1F10-45DE-D301-08DB4187D218'
			},
			{
				label: 'Senegal',
				value: '+221',
				countryId: 'D40203AE-FFDD-464D-D302-08DB4187D218'
			},
			{
				label: 'Srbija',
				value: '+381',
				countryId: '4F0D7B5C-A17F-402F-D303-08DB4187D218'
			},
			{
				label: 'Sejšeli',
				value: '+248',
				countryId: '31063D9B-15DB-4FA0-D304-08DB4187D218'
			},
			{
				label: 'Sijera Leone',
				value: '+232',
				countryId: 'EED64D6B-0D15-4AD3-D305-08DB4187D218'
			},
			{
				label: 'Singapur',
				value: '+65',
				countryId: '5B45542F-7838-4ED5-D306-08DB4187D218'
			},
				{
				label: 'Sint Marten (holandski dio)',
				value: '+1',
				countryId: '4737EFE1-9325-450D-D307-08DB4187D218'
			},
			{
				label: 'Slovačka',
				value: '+421',
				countryId: 'EFBB6094-77F5-48C8-D308-08DB4187D218'
			},
			{
				label: 'Slovenija',
				value: '+386',
				countryId: 'F2BF5697-4F82-4C59-D309-08DB4187D218'
			},
			{
				label: 'Solomonski otoci',
				value: '+677',
				countryId: 'F325D66E-06F8-43F9-D30A-08DB4187D218'
			},
			{
				label: 'Somalija',
				value: '+252',
				countryId: 'DD939F3D-CCB7-47B9-D30B-08DB4187D218'
			},
			{
				label: 'Južna Afrika',
				value: '+27',
				countryId: '155FA8D9-421A-4822-D30C-08DB4187D218'
			},
			{
				label: 'Južna Georgija i Južni Sendvički otoci',
				value: '+500',
				countryId: '4C6F50B4-A939-403B-D30D-08DB4187D218'
			},
			{
				label: 'Koreja (Republika)',
				value: '+82',
				countryId: '00DED49E-4BA3-4A98-D30E-08DB4187D218'
			},
			{
				label: 'Španjolska',
				value: '+34',
				countryId: '38C7E530-1998-41FC-D30F-08DB4187D218'
			},
			{
				label: 'Šri Lanka',
				value: '+94',
				countryId: 'E79AF373-7593-47F2-D310-08DB4187D218'
			},
			{
				label: 'Sudan',
				value: '+249',
				countryId: 'F4E4FE82-F184-459D-D311-08DB4187D218'
			},
			{
				label: 'Južni Sudan',
				value: '+211',
				countryId: 'C06B9CC7-8043-4B87-D312-08DB4187D218'
			},
			{
				label: 'Surinam',
				value: '+597',
				countryId: 'D5020C5B-59D4-4E36-D313-08DB4187D218'
			},
			{
				label: 'Svalbard i Jan Majen',
				value: '+47',
				countryId: 'BE816503-BA14-4848-D314-08DB4187D218'
			},
			{
				label: 'Esvatini',
				value: '+268',
				countryId: '295FC289-9A0C-4E99-D315-08DB4187D218'
			},
			{
				label: 'Švedska',
				value: '+46',
				countryId: '7FC29E32-B070-4A8C-D316-08DB4187D218'
			},
			{
				label: 'Švicarska',
				value: '+41',
				countryId: '1C825BAE-BFE0-4E45-D317-08DB4187D218'
			},
			{
				label: 'Sirijska Arapska Republika',
				value: '+963',
				countryId: '29397097-29FE-4BB2-D318-08DB4187D218'
			},
			{
				label: 'Tajvan',
				value: '+886',
				countryId: 'AE6B6419-AAE1-48A8-D319-08DB4187D218'
			},
			{
				label: 'Tadžikistan',
				value: '+992',
				countryId: 'B962C23A-168A-4858-D31A-08DB4187D218'
			},
			{
				label: 'Tanzanija, Ujedinjena Republika',
				value: '+255',
				countryId: 'C92353FF-42A3-4E35-D31B-08DB4187D218'
			},
			{
				label: 'Tajland',
				value: '+66',
				countryId: 'B7822BC7-65F8-444E-D31C-08DB4187D218'
			},
			{
				label: 'Istočni Timor',
				value: '+670',
				countryId: '3E8C146C-75D2-4D1D-D31D-08DB4187D218'
			},
			{
				label: 'Togo',
				value: '+228',
				countryId: 'B19B530D-F1CE-47E4-D31E-08DB4187D218'
			},
			{
				label: 'Tokelau',
				value: '+690',
				countryId: '5A2398AA-59EB-424D-D31F-08DB4187D218'
			},
			{
				label: 'Tonga',
				value: '+676',
				countryId: '7918834A-0AF0-4E0B-D320-08DB4187D218'
			},
			{
				label: 'Trinidad i Tobago',
				value: '+1',
				countryId: '89AB3E72-BA57-4137-D321-08DB4187D218'
			},
			{
				label: 'Tunis',
				value: '+216',
				countryId: '045E82F0-B710-4E34-D322-08DB4187D218'
			},
			{
				label: 'Turska',
				value: '+90',
				countryId: '59F1511A-0E4F-4F51-D323-08DB4187D218'
			},
			{
				label: 'Turkmenistan',
				value: '+993',
				countryId: '33B0C23C-FB7E-4DC6-D324-08DB4187D218'
			},
			{
				label: 'Otočje Turks i Caicos',
				value: '+1',
				countryId: '51137F41-FF64-4DE8-D325-08DB4187D218'
			},
			{
				label: 'Tuvalu',
				value: '+688',
				countryId: '74EA4C9B-A071-487F-D326-08DB4187D218'
			},
			{
				label: 'Uganda',
				value: '+256',
				countryId: '2EC0AF7D-E3B4-4EEC-D327-08DB4187D218'
			},
			{
				label: 'Ukrajina',
				value: '+380',
				countryId: '32766B0B-8910-445E-D328-08DB4187D218'
			},
			{
				label: 'Ujedinjeni Arapski Emirati',
				value: '+971',
				countryId: 'E2E0F6AC-9E21-4D92-D329-08DB4187D218'
			},
			{
				label: 'Ujedinjeno Kraljevstvo Velike Britanije i Sjeverne Irske',
				value: '+44',
				countryId: '5ECC09A2-86A1-473F-D32A-08DB4187D218'
			},
			{
				label: 'Sjedinjene Američke Države',
				value: '+1',
				countryId: '6611C245-CF8E-4B78-D32B-08DB4187D218'
			},
			{
				label: 'Urugvaj',
				value: '+598',
				countryId: '1464C41D-7B20-4044-D32C-08DB4187D218'
			},
			{
				label: 'Uzbekistan',
				value: '+998',
				countryId: '14BA5FA2-DE2A-4F4E-D32D-08DB4187D218'
			},
			{
				label: 'Vanuatu',
				value: '+678',
				countryId: '6F567CCD-F1EF-4AC5-D32E-08DB4187D218'
			},
			{
				label: 'Venecuela (Bolivarijanska Republika)',
				value: '+58',
				countryId: '1AA82E8A-6041-4354-D32F-08DB4187D218'
			},
			{
				label: 'Vijetnam',
				value: '+84',
				countryId: '358EEB51-AD5A-439A-D330-08DB4187D218'
			},
			{
				label: 'Wallis i Futuna',
				value: '+681',
				countryId: '77002FB5-E22E-4E68-D331-08DB4187D218'
			},
			{
				label: 'Zapadna Sahara',
				value: '+212',
				countryId: '80C88B77-227D-4A58-D332-08DB4187D218'
			},
			{
				label: 'Jemen',
				value: '+967',
				countryId: 'E7D5F9CF-49A8-4F85-D333-08DB4187D218'
			},
			{
				label: 'Zambija',
				value: '+260',
				countryId: '66242D21-081C-4713-D334-08DB4187D218'
			},
			{
				label: 'Zimbabve',
				value: '+263',
				countryId: '3243E7D0-028B-49A9-D335-08DB4187D218'
			}
		]
	}
];