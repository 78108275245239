import { useContext, useEffect } from "react";
import OptionPicker from "../components/OptionPicker";
import { ReservationDataContext } from "../context/reservationDataContext";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Units() {
  const { t } = useTranslation();

  const { reservation, onReservationChange } = useContext(
    ReservationEntryContext
  );
  const targetRoomId = reservation.entries[0].roomId;

  const { units = [] } = useContext(ReservationDataContext);
  const { nextStep } = useContext(ReservationNavigationContext);

  /*
  useEffect(() => {
    onReservationChange({ entries: getNewEntry("") });
  }, [onReservationChange]);*/

  const [targetRoomUnits, setTargetRoomUnits] = useState([]);

  useEffect(() => {
    let targetUnits = units;

    if (targetRoomId !== undefined && targetRoomId !== "") {
      targetUnits = units.filter((x) => x.rooms.includes(targetRoomId));
    }

    setTargetRoomUnits(targetUnits);
  }, [units, targetRoomId]);

  const handleUnitChange = (e) => {
    const entries = getNewEntry(e.target.id);
    Promise.resolve()
      .then(() => onReservationChange({ entries }))
      .then(() => nextStep(entries[0].units));
  };

  const handleNoPreferenceChange = () => {
    const entries = getNoPreferenceEntry();
    Promise.resolve()
      .then(() => onReservationChange({ entries }))
      .then(() => nextStep(entries[0].units));
  };

  const getNewEntry = (unitId) => {
    return [
      {
        roomId: targetRoomId,
        locationActivityId: "",
        durationInMinutes: 0,
        units: [unitId],
      },
    ];
  };

  const getNoPreferenceEntry = () => {
    return [
      {
        roomId: targetRoomId,
        locationActivityId: "",
        durationInMinutes: 0,
        units: [],
      },
    ];
  };

  return (
    <div>
      <p className="large-p">{t("UnitsStep.Title")}</p>

      <div className="page" style={{ maxWidth: "30rem", padding: "0 5%" }}>
        <div className="wrapper">
          {targetRoomUnits.map((unit) => {
            return (
              <OptionPicker
                key={unit.id}
                name="units"
                identity={unit.id}
                text={unit.name}
                handleChange={handleUnitChange}
              />
            );
          })}
          {targetRoomId !== undefined && targetRoomId !== "" ? (
            <OptionPicker
              key="no-preference"
              name="units"
              identity="no-preference"
              text={t("UnitsStep.NoPreference")}
              handleChange={handleNoPreferenceChange}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
