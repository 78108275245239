import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { LOCATION_TYPE } from "../constants/locationType";
import { TranslationLanguages } from "./language-options";
//Translation languages
import translationDE from "./locales/de";
import translationEN from "./locales/en";
import translationHR from "./locales/hr";
import { NAMESPACES } from "./namespaces";

export const locationTypeToNamespace = {
  [LOCATION_TYPE.Unknown]: NAMESPACES.DICTIONARY,
  [LOCATION_TYPE.Clinics]: NAMESPACES.CLINICS,
  [LOCATION_TYPE.NightClub]: NAMESPACES.NIGHT_CLUB,
  [LOCATION_TYPE.Restaurant]: NAMESPACES.RESTAURANT,
  [LOCATION_TYPE.CoWorkingSpace]: NAMESPACES.COWORKING_SPACE,
};

export const getAppLanguage = () => {
  const selectedLng = localStorage.getItem("lang");
  if (selectedLng) return selectedLng;

  return "en";
};

export const setAppLanguage = (lng) => localStorage.setItem("lang", lng);

i18n.use(initReactI18next).init({
  lng: getAppLanguage(),
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  returnNull: false,
  defaultNS: "dictionary",
  debug: true,
  resources: {
    [TranslationLanguages.EN]: translationEN,
    [TranslationLanguages.DE]: translationDE,
    [TranslationLanguages.HR]: translationHR,
  },
});

export default i18n;
