import { useCallback, useContext, useEffect, useState } from "react";
import { ReservationDataContext } from "../context/reservationDataContext";
import { useTranslation } from "react-i18next";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import OptionPicker from "../components/OptionPicker";

export default function Questionnaire() {
  const [select, setSelect] = useState([]);
  const { i18n } = useTranslation();
  const { nextStep } = useContext(ReservationNavigationContext);
  const { questionnaire } = useContext(ReservationDataContext);
  const { reservation, onReservationChange } = useContext(
    ReservationEntryContext
  );

  const [currLanguageObject, setCurrLanguageObject] = useState({});

  useEffect(() => {
    const languages = [{language: "English", code: 'en'}, {language: "Hrvatski", code: 'hr'}];
    setCurrLanguageObject(languages.find(x => x.code === i18n.language));
  }, [i18n.language])

  const handleNumberOfKidsChange = useCallback(
    (e) => {
      const questionnaireValue = {
        id: questionnaire.id,
        answers: [{
          questionId: e.target.name,
          text: e.target.value
        }]
      }
      
      onReservationChange({ questionnaire: questionnaireValue });
      nextStep();
    },
    [onReservationChange, nextStep, questionnaire.id]
  );

  useEffect(() => {
    const PopulateData = async () => {
      let arrayOfInputs = [];

      for (let i = 0; i < +reservation.numberOfPersons + 1; i++) {
        arrayOfInputs.push(
          <OptionPicker
            name={questionnaire.questions[0].id}
            key={i}
            identity={i}
            text={i}
            handleChange={handleNumberOfKidsChange}
          />
        );
      }

      setSelect(arrayOfInputs);
    };

    PopulateData();
  }, [
    handleNumberOfKidsChange,
    reservation.numberOfPersons,
    questionnaire.questions
  ]);

  return (
    <div className="final-container">
      <p className="medium-p" style={{maxWidth: '20rem'}}>
        {
          questionnaire.questions[0].translations.find(x => x.language === currLanguageObject.language) !== undefined ? 
            questionnaire.questions[0].translations.find(x => x.language === currLanguageObject.language).text.replace("numberOfGuests", reservation.numberOfPersons)
            : null
        }
      </p>

      <div className="page">
        <div className="wrapper">{select}</div>
      </div>
    </div>
  );
}