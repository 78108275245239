import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import ReservationInformation from "./ReservationInformation";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

export default function AwatingReservationDetails({code, reservation, start, end}) {
  const { t } = useTranslation();

  return (
    <>
    {reservation != null ? 
        <div className="flex-center">
        <div className="details-container">
          
        <ReservationInformation 
              start={start} 
              startTime={reservation.startTime} 
              endTime={reservation.endTime}
              numberOfPersons={reservation.numberOfPersons} />
          
          <div className="flex-center">
            <QRCode
              size={256}
              fgColor="#173655"
              style={{ height: "auto", maxWidth: "50%", width: "50%", margin: '1em 0'}}
              value={code}
              viewBox={`0 0 256 256`}
            />
          </div>
          
          <div className="flex-center">
            <p className="standard-p">
              {t("ReservationDetails.ThankYou", {
                locationName: reservation.locationName,
              })}
              <br />
              <br />
              {t("ReservationDetails.ShareCode")}
            </p>
          </div>
          
          <div className="flex-center">
            <div className="flex-center">
              <WhatsappShareButton
                url={
                  "https://book-dev.seatlio.com/r/" + code
                }
                quote={t("ReservationDetails.WhatsappQuote", {
                  locationName: reservation.locationName,
                })}
              >
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
            </div>
          </div>
        </div>
      </div>
      : null}
    </>
  );
}