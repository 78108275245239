import { useMatch, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GetRequest } from "../api/requests/getRequest";
import AwatingReservationDetails from '../components/AwatingReservationDetails';
import ExpiredReservationDetails from '../components/ExpiredReservationDetails';
import { useTranslation } from "react-i18next";
import LanguagePicker from "../components/LanguagePicker";
import PayNow from "../components/PayNow";

export default function ReservationDetails() {
  const currentDate = new Date();
  const [reservation, setReservation] = useState(null);
  const [end, setEnd] = useState(currentDate);
  const [start, setStart] = useState(currentDate);
  const [shouldDisplayPaymentButton, setShouldDisplayPaymentButton] = useState(false);

  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [hasBeenCanceled, setHasBeenCanceled] = useState(false);

  useEffect(() => {
    if(searchParams.get("isSuccess") === "true") setIsSuccess(true)
    if(searchParams.get("rejectReason") != null) setPaymentError(searchParams.get("rejectReason"))
  }, [searchParams])

  const match = useMatch("/r/:reservationId");
  const code = match?.params.reservationId;


  const { t } = useTranslation();

  useEffect(() => {
    const LoadReservationData = async () => {
      try {
        await GetRequest(`/api/v1/reservations/${code}`).then(
          response => {
            setReservation(response) 
            setEnd(new Date(response.end))
            setStart(new Date(response.start))
          }
        );
      } catch {
        // NAVIGIRAJ NEGDI
      }
    };

    LoadReservationData();
  }, [code, isSuccess, hasBeenCanceled]);

  useEffect(() => {
    if(reservation != null)
      setShouldDisplayPaymentButton(reservation.payment.paymentUrl !== null && reservation.payment.paymentUrl !== "");
  }, [reservation])

  return (
    <>
      <nav className="nav">
        <div className="navigation-bar container">
          <div className="item-left">
            <ul className="menu-list">
              <li>
                <a style={{color: '#edf2f7'}} href="https://seatlio.com/terms-of-agreement.html">
                  {t("Legal")}
                </a>
              </li>
            </ul>
          </div>
          <div className="item-center">
            <ul className="menu-list">
              <li>
                <b>{reservation != null ? reservation.locationName : null}</b>
              </li>
            </ul>
          </div>

          <div className="item-right">
            <div className="item-right">
              <div className="dropdown">
                <LanguagePicker />
              </div>
            </div>
          </div>
        </div>
        <div className="menu-backdrop"></div>
      </nav>

      { shouldDisplayPaymentButton
          ? <PayNow reservation={reservation} start={start} paymentError={paymentError} setHasBeenCanceled={setHasBeenCanceled} />
          : ( end.getTime() > currentDate.getTime() ? 
              ( reservation.status !== 30 ? 
                <AwatingReservationDetails reservation={reservation} code={code} start={start} end={end} />
                : <ExpiredReservationDetails reservation={reservation} start={start} end={end} />
              )
              : <ExpiredReservationDetails reservation={reservation} start={start} end={end} />
            )
      }
    </>
  );
}