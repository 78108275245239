export const TranslationLanguages = {
  EN: "en",
  DE: "de",
  HR: "hr",
};

export const languageOptions = [
  { label: "English", value: TranslationLanguages.EN },
  { label: "Deutsch", value: TranslationLanguages.DE },
  { label: "Hrvatski", value: TranslationLanguages.HR },
];
