import { NAMESPACES } from "../../namespaces";
import { dictionaryDE } from "./dictionary";
import clinicsDE from "./clinics-de.json";
import nightClubDE from "./night-club-de.json";
import restaurantDE from "./restaurant-de.json";
import coWorkingSpaceDE from "./co-working-space-de.json";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [NAMESPACES.DICTIONARY]: dictionaryDE,
  [NAMESPACES.RESTAURANT]: { ...dictionaryDE, ...restaurantDE },
  [NAMESPACES.CLINICS]: { ...dictionaryDE, ...clinicsDE },
  [NAMESPACES.NIGHT_CLUB]: { ...dictionaryDE, ...nightClubDE },
  [NAMESPACES.COWORKING_SPACE]: { ...dictionaryDE, ...coWorkingSpaceDE },
};
