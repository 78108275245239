export const formatToYearMonthDay = (date, dateFormat) => {
    var year = date.toLocaleString(dateFormat, { year: "numeric" });
    var month = date.toLocaleString(dateFormat, { month: "2-digit" });
    var day = date.toLocaleString(dateFormat, { day: "2-digit" });
    return year + "-" + month + "-" + day;
}

export const formatToDayMonthYear = (date, dateFormat) => {
    var year = date.toLocaleString(dateFormat, { year: "numeric" });
    var month = date.toLocaleString(dateFormat, { month: "long" });
    var day = date.toLocaleString(dateFormat, { day: "2-digit" });
    return day + " " + month + " " + year + "";
}

export const isToday = (dateToCheck) => {
    const today = new Date();
 
    const isSameDate =
        dateToCheck.getDate() === today.getDate() &&
        dateToCheck.getMonth() === today.getMonth() &&
        dateToCheck.getFullYear() === today.getFullYear();
 
    return isSameDate;
};

export const isInThePast = (dateToCheck) => {
    const date = new Date(dateToCheck);
    const today = new Date();

    if (today.setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)) {
        return true;
    }
    
    return false;
}