export const RESERVATION_MODEL = {
    name: '',
    note: '',
    email: '',
    locationId: '',
    countryId: '361df93a-c799-4b42-d275-08db4187d218',
    phone: '',
    start: '',
    startDate: '',
    startTime: '',
    numberOfPersons: 1,
    subscribedToNewsletter: false,
    agreedToTerms: false,
    entries: [
      {
        roomId: '',
        locationActivityId: '',
        durationInMinutes: 0,
        units: []
      }
    ],
    skipOptimization: true,
    questionnaire: {}
}