import React from "react";
import { createContext } from "react";
import { useState } from "react";
import { RESERVATION_MODEL } from "../models/reservation";
import { useCallback } from "react";

export const ReservationEntryContext = createContext({});

export const ReservationEntryProvider = ({ children }) => {
  const [reservation, setReservation] = useState(
    JSON.parse(sessionStorage.getItem("reservation")) || RESERVATION_MODEL
  );

  const onReservationChange = useCallback((newData) => {
    setReservation((prevReservation) => {
      const newReservation = { ...prevReservation, ...newData };
      sessionStorage.setItem("reservation", JSON.stringify(newReservation));

      return newReservation;
    });
  }, []);

  return (
    <ReservationEntryContext.Provider
      value={{ reservation, onReservationChange }}
    >
      {children}
    </ReservationEntryContext.Provider>
  );
};
