import { useContext } from "react";
import exit from "../images/exit.png";
import { ReservationDataContext } from "../context/reservationDataContext";

export default function VideoStory({storyVideoData, resetVideoStoryToDefault}) {
    const { locationName } = useContext(ReservationDataContext);

    return (
        <>
            { storyVideoData.isStoryVideoOpen === true && 
                <div style={{zIndex: '10', position: "fixed", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0)"}}>      
                    <div style={{width: '100%', height: '7.5%', marginTop: '1em', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute'}}>
                        <div style={{width: '80%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <div style={{ height: '80%', aspectRatio: '1', marginLeft: '1em', borderRadius: '50%', overflow: 'hidden'}}>
                                <img alt="company logo" src={storyVideoData.imageUrl} style={{height: '100%', width: '100%'}} /> 
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', color: 'white'}}>
                                <h1 style={{fontSize: '1.2em', margin: '0 0 0 0.7em'}}>{locationName} </h1>
                                <h1 style={{fontSize: '0.9em', margin: '0 0 0 1em'}}>{storyVideoData.activityName} </h1>
                            </div>
                        </div>
                        <div onClick={() => resetVideoStoryToDefault()} style={{width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2'}}>
                            <img alt="exit button" src={exit} style={{height: '40%', aspectRatio: '1/1'}}  />
                        </div>
                    </div>
                    
                    <video autoPlay muted loop preload="none" style={{minWidth: '100%', minHeight: '100%', height: '100%', margin: '0', overflow: 'hidden'}} >
                        <source type="video/mp4" src={storyVideoData.videoUrl} />
                    </video>
                </div>
            }
        </>
    );
  }