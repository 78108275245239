import Select from "react-select";
import { useState, useContext, useEffect } from "react";
import Confirmation from "../components/Confirmation";
import { COUNTRY_CODES } from "../constants/countryCodes";
import { formatToDayMonthYear } from "../helpers/useDateHelpers";
import { ReservationDataContext } from "../context/reservationDataContext";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { useTranslation } from "react-i18next";

export const formatPhone = (number, prefix) => {
  if (number.length < prefix.length)
    return { prefix: prefix + " ", number: "", formatted: prefix + " " };

  const digits = number.replace(prefix, "").replace(/([^0-9])/g, "");

  return {
    prefix: prefix + " ",
    number: digits,
    formatted: [prefix, digits.slice(0, 2), digits.slice(2, 6), digits.slice(6)]
      .filter((part) => part)
      .join(" "),
  };
};

export default function UserData() {
  const [errorMessageKey, setErrorMessageKey] = useState("");
  const { t, i18n } = useTranslation();
  const [allCountryCodes, setAllCountryCodes] = useState(COUNTRY_CODES.find(x => x.lang === i18n.language).data);
  const [countryCode, setCountryCode] = useState(
    COUNTRY_CODES.find(x => x.lang ===  i18n.language).data.find(country => country.value === "+385")
  );
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("+385 ");
  const { locationName, activities, shouldHideNote } = useContext(
    ReservationDataContext
  );
  const { reservation, onReservationChange } = useContext(
    ReservationEntryContext
  );

  useEffect(()=> {
    setAllCountryCodes(COUNTRY_CODES.find(x => x.lang === i18n.language).data);
  }, [i18n.language])

  const handleCountryCodeChange = (event) => {
    setCountryCode(event);

    const { formatted, number } = formatPhone(reservation.phone, event.value);

    setFormattedPhoneNumber(formatted);

    onReservationChange({
      countryId: event.countryId,
      phone: number,
    });
  };

  const handleChange = (event) => {
    setErrorMessageKey("");
    const { name } = event.target;
    onReservationChange({ [name]: event.target.value });
  };

  const handlePhoneNumberChange = (event) => {
    setErrorMessageKey("");
    const { name } = event.target;
    const { formatted, number } = formatPhone(
      event.target.value,
      countryCode.value
    );
    setFormattedPhoneNumber(formatted);
    onReservationChange({ [name]: number });
  };

  const handleCheckboxChange = (event) => {
    setErrorMessageKey("");
    const { name } = event.target;
    onReservationChange({ [name]: event.target.checked });
  };

  const onLabelClick = (event) => {
    const name = event.target.id;
    onReservationChange({ [name]: !reservation[name] });
  };

  return (
    <div style={{ maxWidth: "25rem", padding: "0 5%" }}>
      <h1 className="large-p">{t("UserDataStep.Title")}</h1>
      <div className="reservation-details-container">
        <div>
          <p className="details-container-value">{formatToDayMonthYear(reservation.startDate, i18n.language)} | {reservation.startTime.substring(0, 5)}</p>
          <p className="details-container-desc">{t("UserDataStep.TimeOfReservation")}</p>
        </div>
        
        {activities.find((x) => x.id === reservation.entries[0].locationActivityId)?.name !== undefined ?
            <div>
              <p className="details-container-value">
               {activities.find((x) => x.id === reservation.entries[0].locationActivityId)?.name}
              </p>
              <p className="details-container-desc">{t("UserDataStep.Seating")}</p>
            </div> 
          : null
        }
        
        { reservation.numberOfPersons !== 1 && reservation.numberOfPersons !== undefined ? 
          <div>
            <p className="details-container-value">{reservation.numberOfPersons + " " + t("UserDataStep.Persons")}</p>
            <p className="details-container-desc">{t("UserDataStep.NumberOfPersons")}</p>
          </div> : null
        }
      </div>
      <h1 className="large-p" style={{marginTop: '2em'}}>{t("UserDataStep.Subtitle")}</h1>
      <div className="input_wrap">
        <input
          required
          name="name"
          htmlFor="name"
          onChange={handleChange}
          className="ph-input"
          type="text"
          defaultValue={reservation.name}
          autoComplete="off"
        />
        <label>{t("UserDataStep.NameLabel")}</label>
      </div>
      <div className="input_wrap">
        <input
          required
          name="email"
          htmlFor="email"
          onChange={handleChange}
          className="ph-input"
          type="text"
          defaultValue={reservation.email}
          autoComplete="off"
        />
        <label>{t("UserDataStep.EmailLabel")}</label>
      </div>
      <div className="input_wrap">
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: "#5286B5",
              color: '#173655',
              borderRadius: '16px',
              fontSize: "1em",
              padding: "5px 0 5px 0.8em",
              outline: "none"
            }),
          }}
          placeholder="Country code"
          defaultValue={countryCode}
          onChange={handleCountryCodeChange}
          options={allCountryCodes}
        />
      </div>

      <div className="input_wrap">
        <input
          required
          name="phone"
          htmlFor="phone"
          onChange={handlePhoneNumberChange}
          className="ph-input"
          value={formattedPhoneNumber}
          autoComplete="off"
        />
        <label>{t("UserDataStep.PhoneLabel")}</label>
      </div>

      {shouldHideNote === false ? 
        <div className="input_wrap">
          <input
            required
            name="note"
            htmlFor="note"
            onChange={handleChange}
            className="ph-input"
            type="text"
            defaultValue={reservation.note}
            autoComplete="off"
          />
          <label>{t("UserDataStep.NoteLabel")}</label>
        </div> 
      : null}
      
      <div className="checkbox-wrap">
        <input
          required
          checked={reservation.subscribedToNewsletter}
          name="subscribedToNewsletter"
          onChange={handleCheckboxChange}
          style={{ width: '15px', height: '15px', minWidth: '15px', minHeight: '15px' }}
          className="terms-checkbox"
          type="checkbox"
          autoComplete="off"
        />
        <label id="subscribedToNewsletter" onClick={onLabelClick}>
          {t("UserDataStep.DiscountOption", { locationName })}
        </label>
      </div>
      <div className="checkbox-wrap">
        <input
          required
          checked={reservation.agreedToTerms}
          style={{ width: '15px', height: '15px', minWidth: '15px', minHeight: '15px' }}
          name="agreedToTerms"
          onChange={handleCheckboxChange}
          className="terms-checkbox"
          type="checkbox"
          autoComplete="off"
        />
        <label id="agreedToTerms" onClick={onLabelClick}>
          {t("UserDataStep.IAgreeCheckbox")}
        </label>
      </div>

      {errorMessageKey !== "" ? (
        <div className="error-message-container">
          <p className="error-message">{t(errorMessageKey)}</p>
        </div>
      ) : null}

      <Confirmation
        reservation={reservation}
        setErrorMessageKey={setErrorMessageKey}
      />
    </div>
  );
}
