import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { LocationTypeContext } from "../context/locationContext";
import { locationTypeToNamespace } from "../i18n/load-translations";

const useTranslationLocationType = () => {
  const { i18n } = useTranslation();
  const { type } = useContext(LocationTypeContext);

  i18n.setDefaultNamespace(locationTypeToNamespace[type]);
};

export default useTranslationLocationType;
