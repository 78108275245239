import { useContext } from "react";
import { useState } from "react";
import { ReservationDataContext } from "../context/reservationDataContext";
import * as crs from "../constants/reservationSteps";

import { useEffect } from "react";
import { useCallback } from "react";

const filterSteps = (steps, activities, selectedUnits = []) => {
  const showActivitiesStep =
    selectedUnits.length === 0 ||
    selectedUnits.some((unitId) =>
      activities.some(({ units }) => units.some(({ id }) => id === unitId))
    );

  return steps.filter((step) => {
    switch (step?.id) {
      case crs.ACTIVITIES_STEP:
        return showActivitiesStep;
      default:
        return true;
    }
  });
};

export const useReservationNavigation = () => {
  const { steps = [], activities = [], locationName } = useContext(ReservationDataContext);
  const [step, setStep] = useState(steps[0]);
  const [title, setTitle] = useState(locationName);
  const [filteredSteps, setFilteredSteps] = useState(steps);

  useEffect(() => {
    const newFilteredSteps = filterSteps(steps, activities);
    setFilteredSteps(newFilteredSteps);

    if (!step) setStep(steps[0]);
  }, [steps, activities, step]);

  const prevStep = useCallback(
    (selectedUnits) => {
      const newFilteredSteps = filterSteps(steps, activities, selectedUnits);
      let currIndex = newFilteredSteps.findIndex(({ id }) => id === step?.id);

      if (currIndex === -1) setStep(steps[0]);
      else setStep(newFilteredSteps[currIndex - 1]);

      setFilteredSteps(newFilteredSteps);
    },
    [activities, step?.id, steps]
  );

  const nextStep = useCallback(
    (selectedUnits) => {
      const newFilteredSteps = filterSteps(steps, activities, selectedUnits);
      let currIndex = newFilteredSteps.findIndex(({ id }) => id === step?.id);

      setStep(newFilteredSteps[currIndex + 1]);

      setFilteredSteps(newFilteredSteps);
    },
    [activities, step?.id, steps]
  );

  const resetNavigationData = useCallback(() => {
    setStep(steps[0]);
    setFilteredSteps(steps);
  }, [steps]);

  return { prevStep, nextStep, step, filteredSteps, resetNavigationData, title, setTitle };
};
