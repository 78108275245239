import axiosRequest from '../lib/axios';

export const PostRequest = async (url, payload) => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;
    var config = { signal };

    try{
        const response = await axiosRequest
            .post(url, payload, config)
            .then(x => x.data);
            
        if(!isMounted) throw new Error("Not mounted");
        return response;
    } catch {
        isMounted = false;
        controller.abort();
    }
}