import gbFlag from "../images/gb.png";
import deFlag from "../images/de.png";
import hrFlag from "../images/hr.png";
import { ReactComponent as ChevronDown } from "../images/chevron-down.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRef } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { setAppLanguage } from "../i18n/load-translations";
import { languageOptions } from "../i18n/language-options";

const flags = {
  en: gbFlag,
  de: deFlag,
  hr: hrFlag,
};

const LanguagePicker = () => {
  const ref = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const { i18n } = useTranslation();

  const languagesWithoutDE = languageOptions.filter(
    (language) => language.value !== "de"
  );

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setAppLanguage(language);
    setShowDropdown(false);
  };

  useOnClickOutside(ref, () => setShowDropdown(false));

  return (
    <div ref={ref}>
      <button
        className="dropdown-button"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <img src={flags[i18n.language]} alt="Language" />
        <span data-show={showDropdown}>
          <ChevronDown />
        </span>
      </button>
      <ul className="dropdown-content" data-show={showDropdown}>
        {languagesWithoutDE.map((language) => (
          <li
            key={language.value}
            onClick={() => handleLanguageChange(language.value)}
          >
            <img src={flags[language.value]} alt={language.label} />{" "}
            {language.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguagePicker;
