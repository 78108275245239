import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const defaultFormat = "hr-HR";

export default function ReservationInformation({start, startTime, endTime, numberOfPersons}) {
  const { t, i18n } = useTranslation();
  const [languageCode, setLanguageCode] = useState(defaultFormat);

  useEffect(() => {
    if(i18n.language === 'hr')  setLanguageCode("hr-HR")
    else setLanguageCode("en-US")
  }, [i18n.language])
  
  return (
    <p className="standard-p">
          <b>
            {start.toLocaleDateString(languageCode)}
            &nbsp; | &nbsp;
            {startTime + ' - ' + endTime}
            &nbsp; | &nbsp;
            {numberOfPersons + " " + t("UserDataStep.Persons") + " "}
          </b>
        </p>
  );
}