import React from "react";
import { createContext } from "react";
import { useReservationData } from "../hooks/useReservationData";
import { useEffect } from "react";
import { useContext } from "react";
import { ReservationEntryContext } from "./reservationEntryContext";

export const ReservationDataContext = createContext({});
export const LocationsContext = createContext([]);

export const ReservationDataProvider = ({ children }) => {
  const data = useReservationData();
  const { onReservationChange } = useContext(ReservationEntryContext);

  useEffect(() => {
    if (!data.currentLocation?.locationId) return;

    onReservationChange({
      locationId: data.currentLocation.locationId,
    });
  }, [data, onReservationChange]);

  return (
    <LocationsContext.Provider value={data || []}>
      <ReservationDataContext.Provider value={data.currentLocation}>
        {children}
      </ReservationDataContext.Provider>
    </LocationsContext.Provider>
  );
};
