import { useTranslation } from "react-i18next";
import ReservationInformation from "./ReservationInformation";

export default function ClientConfirmationDetails({reservation, start, end}) {
  const { t } = useTranslation();

  const handleStatusChange = (status) => {
    alert(status);
  }

  return (
    <div className="flex-center">
      <div className="details-container">
        <p className="large-p" style={{ width: "100%", marginBottom: '1em' }}>
          {t("Confirmation.Title")}
        </p>
        <p className="standard-p">
          <b>{reservation.locationName}</b>
        </p>
        
        <ReservationInformation 
              start={start} 
              startTime={reservation.startTime} 
              endTime={reservation.endTime}
              numberOfPersons={reservation.numberOfPersons} />

        <div style={{ display: "flex", justifyContent: "space-around", marginTop: '1em' }}>
          <button onClick={() => handleStatusChange(30)} className="card-button" style={{ width: "45%", color: '#173655', border: '1px solid #173655', backgroundColor: '#F7FAFC' }}>
            {t("Confirmation.Cancel")}
          </button>

          <button onClick={() => handleStatusChange(25)} className="card-button" style={{ width: "45%" }}>
            {t("Confirmation.Confirm")}
          </button>
        </div>
      </div>
    </div>
  );
}