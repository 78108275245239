import { useMatch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GetRequest } from "../api/requests/getRequest";
import { PatchRequest } from "../api/requests/patchRequest";
import { useTranslation } from "react-i18next";
import LanguagePicker from "../components/LanguagePicker";
import ReservationInformation from "../components/ReservationInformation";
import swal from 'sweetalert';

export default function ReservationCancellation() {
  const currentDate = new Date();
  const [reservation, setReservation] = useState({});
  const [start, setStart] = useState(currentDate);
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenCanceled, setHasBeenCanceled] = useState(false);

  const match = useMatch("/cancel/:reservationId");
  const code = match?.params.reservationId;
  const { t } = useTranslation();
  
  useEffect(() => {
    const LoadReservationData = async () => {
      setIsLoading(true);
      try {
        await GetRequest(`/api/v1/reservations/${code}`).then(
          response => {
            setReservation(response) 
            setStart(new Date(response.start))
          }
        );
      } catch {
        // NAVIGIRAJ NEGDI
      }
      setIsLoading(false);
    };

    LoadReservationData();
  }, [code]);

  const handleCancelation = () => {
    swal({
      title: t("Cancelation.Title"),
      text: t("Cancelation.Text"),
      buttons: [t("Cancelation.Cancel"), t("Cancelation.Confirm")]
    })
    .then((willDelete) => {
      if (willDelete) {
        try {
          PatchRequest(`/api/v1/reservations/${reservation.id}/cancel`);
        } catch {
          // NAVIGIRAJ NEGDI
        }
        setHasBeenCanceled(true);
        swal(t("Cancelation.Success"));
      }
    });
  }

  return (
    <>
      <nav className="nav">
        <div className="navigation-bar container">
          <div className="item-left">
            <ul className="menu-list">
              <li>
                <a href="https://seatlio.com/terms-of-agreement.html">
                  {t("Legal")}
                </a>
              </li>
            </ul>
          </div>
          <div className="item-center">
            <ul className="menu-list">
              <li>
                <b>{reservation != null ? reservation.locationName : null}</b>
              </li>
            </ul>
          </div>

          <div className="item-right">
            <div className="item-right">
              <div className="dropdown">
                <LanguagePicker />
              </div>
            </div>
          </div>
        </div>
        <div className="menu-backdrop"></div>
      </nav>
      

        <div className="flex-center" style={{marginTop: '2em'}}>
          <div className="details-container">
            
            <ReservationInformation 
              start={start} 
              startTime={reservation.startTime} 
              endTime={reservation.endTime}
              numberOfPersons={reservation.numberOfPersons} />

            {reservation.status === 30 || hasBeenCanceled || !reservation.isCancellable ? 
              
              <p className="standard-p">
                {t("Cancelation.NotAccessible")}
              </p>
              :  
              <>
                <p className="standard-p">
                  {t("Cancelation.Proceed")}
                </p>

                <button className="next-button" onClick={handleCancelation} disabled={isLoading}>
                  {isLoading ? t("Cancelation.Loading") : t("Cancelation.Submit")}
                </button>
              </> 
            }
          </div>
        </div>
    </>
  );
}