import { Link } from "react-router-dom";
import Maestro from "../assets/maestro.svg"
import Visa from "../assets/visa.svg"
import MasterCard from "../assets/mastercard.svg"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PatchRequest } from "../api/requests/patchRequest";
import swal from 'sweetalert';

const defaultFormat = "hr-HR";

export default function PayNow({reservation, start, setHasBeenCanceled, paymentError = null}) {
  const { t, i18n } = useTranslation();
  const [languageCode, setLanguageCode] = useState(defaultFormat);

  useEffect(() => {
    if(i18n.language === 'hr') setLanguageCode("hr-HR")
    else setLanguageCode("en-US")
  }, [i18n.language])

  const handleCancelation = async () => {
    swal({
      title: t("Cancelation.Title"),
      text: t("Cancelation.Text"),
      buttons: [t("Cancelation.Cancel"), t("Cancelation.Confirm")]
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          await PatchRequest(`/api/v1/reservations/${reservation.id}/cancel`);
        } catch {
          // NAVIGIRAJ NEGDI
        }
        setHasBeenCanceled(true);
      }
    });
  }

  return (
    <>
    {reservation != null ? 
        <div className="flex-center">
          <div className="details-container" style={{backgroundColor: '#EDF2F7', width: '75%', maxWidth: '20rem', marginTop: '3em', marginBottom: '3em', paddingTop: '1em', borderRadius: '25px'}}>
            <h2 style={{marginBottom: '0.5em'}}>
              {reservation.payment.processType === 0 ? t("PaymentRequired.Header") : t("PaymentRequired.HeaderHold") }</h2>
            <p style={{marginTop: 0}}>{t("PaymentRequired.Description")}</p>

            <hr style={{borderBottom: '1px solid #173655'}} />

            <div style={{width: '100%',  display: 'flex', flexDirection: 'row'}}>
              <div style={{width: '50%'}}>
                  <p>{t("PaymentRequired.Holder")}</p>
                  <p>{t("PaymentRequired.Phone")}</p>
                  <p>{t("PaymentRequired.Date")}</p>
                  <p>{t("PaymentRequired.Guests")}</p>
                  <p>{t("PaymentRequired.Start")}</p>
                  <p>{t("PaymentRequired.End")}</p>
                  <p>{t("PaymentRequired.Price")}</p>
              </div>
              <div style={{width: '50%', textAlign: 'end', fontWeight: 'bold'}}>
                  <p>{reservation.reservationHolder}</p>
                  <p>{reservation.phone}</p>
                  <p>{start.toLocaleDateString(languageCode)}</p>
                  <p>{reservation.numberOfPersons} {t("PaymentRequired.Persons")}</p>
                  <p>{reservation.startTime}</p>
                  <p>{reservation.endTime}</p>
                  <p>{reservation.payment.totalAmountFormatted}</p>
              </div>
            </div>

            {paymentError != null ?  <p style={{textAlign: 'center'}}>{paymentError}</p> : null }

            <div className="flex-center">
              {reservation != null ?
                  <>
                    {reservation.isCancellable ? 
                      <button style={{height: '50%', }} className="secondary-button" onClick={handleCancelation} >
                        {t("PaymentRequired.Cancel")}
                      </button> : null}
                    
                    <Link className="next-button" to={reservation.payment.paymentUrl}> 
                      {reservation.payment.processType === 0 ? t("PaymentRequired.PayNow") : t("PaymentRequired.ReserveFunds")} 
                    </Link>
                  </>
                  : null
              }
            </div>

            <div className="flex-center" style={{marginBottom: '2em'}}>
              <img src={MasterCard} alt="Mastercard logo" height="30px" />
              <img src={Maestro} alt="Maestro logo" height="30px" />
              <img src={Visa} alt="Visa logo" height="20px" />
            </div>
          </div>
        </div>
      : null}
    </>
  );
}